.refer_heading {
	@media screen and (max-width: 992px) {
		max-width: 350px;
	}
	& h2 {
		text-align: start;
		padding: 0 0 1rem 0;
	}
}
.copyLink-button {
	padding: 0.6rem;
	// width: 50%;
	width: 100%;
	border-radius: 0.25rem;
	font-weight: 600;
	cursor: pointer;
	background: var(--secondaryBtn);
	color: var(--button-text);
	border: none;
	// border-color: var(--secondry-border);
	opacity: 1;
	box-shadow:
		rgba(255, 176, 25, 0.4) 0px 0px 10px,
		rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
		rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
		rgb(255, 135, 25) 0px 0px 15px inset;
	@media (max-width: '600px') {
		width: 100%;
	}
}
.referral-code-title {
	color: #fff;
	font-weight: 600 !important;
}

.referral_ref {
	padding-bottom: 0 !important;
}
.refer-banner {
	width: 100%;
	text-align: start;
	& img {
		width: 100%;
	}
}
