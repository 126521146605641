.header {
	width: 100%;
	height: var(--headerHeight);
	background: var(--darkGunmetal-1000);
	border-bottom: 1px solid var(--black-500);
	display: flex;
	align-items: center;
	z-index: 5;
	padding: 0 1rem;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

	& .Menu-closeIcon {
		cursor: pointer;
		& svg {
			fill: #fff;
		}
	}

	.header-content {
		flex-grow: 1;
		align-items: center;
	}

	.default-btn {
		background: var(--socialLinksBg);
		border-color: var(--socialLinksBg);
		box-shadow: none;
		color: var(--white);
		min-width: 40px;
		svg {
			width: 19px;
			path {
				fill: var(--white);
			}
		}
	}

	.header-right {
		display: flex;
		align-items: center;
		gap: 10px;
		.wallet-dropdown-container {
			@media (max-width: '767px') {
				display: none;
			}
			.wallet-dropdown {
				display: flex;
				align-items: center;
				position: relative;

				& > img {
					position: absolute;
					z-index: 2;
					left: 10px;
				}

				.selectProvider {
					min-width: 200px;
					@media (max-width: '767px') {
						min-width: 200px;
					}
					@media (max-width: '560px') {
						min-width: 50px;
					}
					.selectProvider__control {
						background-color: var(--socialLinksBg);
						border-color: var(--socialLinksBg);
						box-shadow: none;
						border-radius: 4px;
						cursor: pointer;
					}
					// .selectProvider__value-container {
					// 	// padding-left: 30px;
					// 	& .selectProvider__multi-value {
					// 		&.selectProvider__multi-value__label  {
					// 			color:#fff
					// 		}
					// 	}

					// }
					.selectProvider__menu-list {
						padding: 0;
					}
					.selectProvider__single-value {
						font-weight: 600;
					}
					.selectProvider__option {
						display: flex;
						font-weight: 600;
						justify-content: space-between;
						// &:hover {
						// 	background-color: red;
						// }
					}
				}
			}
		}
	}

	.brand-logo {
		flex-grow: 1;
		padding-left: 0.7rem;
		.brand-logo-size {
			width: 6.5rem;
			height: 3.5rem;
		}

		svg {
			width: 195px;

			@media (max-width: 1199px) {
				width: 50px;
				height: 50px;
			}
		}
	}

	.header-social-links {
		a {
			height: 2.75rem;
			width: 2.75rem;
			border-radius: 8px;
			background: var(--socialLinksBg);
			margin: 0 0.625rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&.sidebar-close {
		// width: 100%;
		// max-width: calc(100% - 40px);
		.brand-logo {
			@media only screen and (min-width: 767px) and (max-width: 1480px) {
				margin-left: 0;
			}
		}
	}

	&.chat-close {
		@media (min-width: 640px) {
			right: 0px;
			transition: width 0.3s ease;
		}
	}

	&.chat-open {
		@media (min-width: 640px) {
			right: var(--chatDrawerWidth);
			width: calc(100% - var(--chatDrawerWidth));
			transition: width 0.3s ease;
		}
	}

	@media (max-width: 1024px) {
		right: 0px;
	}

	.auth-btn {
		min-height: 40px;
		font-weight: 600;
		border-radius: 5px;
		&.btn-primary {
			background: var(--authPrimryBtn);
			min-width: 133px;
			border: 1px solid var(--secondaryBtnBorder);
			box-shadow: none;
			color: var(--white);
			font-family: 'Poppins', sans-serif;
			text-transform: capitalize;

			&:hover {
				background: var(--nft-badge);
			}

			@media (max-width: 991px) {
				font-size: 0.875rem;
			}

			@media (max-width: 767px) {
				min-width: 70px;
				font-size: 0.75rem;
			}

			@media screen and (min-width: 767px) {
				width: 40px;
			}
		}

		&.btn-secondary {
			min-width: 133px;
			background: var(--secondaryRadialBtn);
			border-color: var(--secondry-border);
			font-family: 'Poppins', sans-serif;
			text-transform: capitalize;

			@media (max-width: 991px) {
				font-size: 0.875rem;
			}

			@media (max-width: 767px) {
				min-width: 70px;
				font-size: 0.75rem;
			}
		}
	}

	.search-btn {
		background: var(--authPrimryBtn);
		border: 1px solid var(--secondaryBtnBorder);
		width: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;

		&:hover {
			background: var(--nft-badge);
			border-color: transparent !important;
			svg {
				filter: none !important;

				path {
					fill: var(--white);
				}
			}
		}

		& svg {
			height: 1.2rem;
			width: 1.2rem;

			path {
				fill: var(--white);
			}
		}
	}

	.theme-modal {
		.modal-dialog {
			position: relative;
		}
	}

	.btn-primary {
		background: var(--socialLinksBg);
		border-color: var(--socialLinksBg);
		box-shadow: none;
		color: var(--white);

		&:hover {
			// background: var(--nft-badge);
			border-color: transparent !important;

			& svg {
				filter: brightness(10);
			}
		}
	}
}

.search-modal {
	background-color: var(--inputbg) !important;

	.search-modal-wrap {
		padding: 0.625rem;

		.nav-link {
			background: var(--authTabBtn);
			min-width: 100%;
			width: 100%;
			margin: 0;
			justify-content: flex-start;
			border: none;
			font-size: 0.875rem;
			font-weight: 500;
			border-radius: 0;

			&.active,
			&:hover {
				background: var(--profileCard);

				& img {
					filter: brightness(10);
				}
			}

			& img {
				margin-right: 0.8rem;
				width: 1rem;
			}
		}

		.search-modal-right-section {
			padding: 1rem;

			.golabl-casino-section {
				height: 70vh;
				overflow-y: scroll;
				scroll-behavior: smooth;
			}

			@media (max-width: 767px) {
				padding: 0;
			}

			.search-modal-heading {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 1rem;

				h4 {
					color: var(--white);
					margin: 0;
					font-weight: 600;
					text-transform: capitalize;
					font-size: 1.5rem;
				}

				.btn-close {
					filter: invert(20);
					opacity: 1;
				}
			}

			.casino-search {
				flex: 1;
				margin-right: 0;

				input {
					height: 45px;
				}
			}

			.search-filter-right {
				.btn-primary {
					font-size: 0.875rem;

					span {
						color: var(--button-text);
					}
				}

				@media (max-width: 767px) {
					justify-content: space-between;

					& .btn {
						width: 100%;
					}
				}
			}

			.golabl-casino-section {
				padding: 0 0.625rem;
			}

			.load-more-wrap {
				.btn {
					@media (max-width: 767px) {
						justify-content: center;
					}
				}
			}
		}

		.profile-tabs {
			position: sticky;
			top: 1rem;
			background: var(--authTabBtn);
			border-radius: 8px;
			padding: 5px;
			overflow: hidden;
		}
	}
}

.auth-modal-wrap {
	@media (max-width: 1199px) {
		min-width: 80%;
	}
}

.casino-header {
	padding: 20px 15px 20px 75px;
}

@media screen and (max-width: 767.98px) {
	.currency-wrapper .btn-wallet {
		padding: 4px 11px;
	}
}

header .menuBtn {
	display: none;
	z-index: 1;
}

header .menuBtn.toggleBtn {
	transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
}

.chat-sidebar {
	position: fixed;
	right: 0;
	top: 3.8rem;
	background: var(--sidebar-bg);
	height: calc(100vh - 50px);
	width: 248px;
	padding: 0.625rem;

	&.chat-collapse {
		width: 340px;

		@media (max-width: 767px) {
			width: 100%;
		}

		.chat-collapse-icon {
			i {
				transform: rotate(180deg);
			}
		}

		.btn-secondary {
			min-width: 110px;
		}
	}

	@media (max-width: 767px) {
		width: 100%;
	}

	.chat-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.chat-icon {
			svg {
				text-shadow: var(--text-shadow);
				filter: var(--drop-shadow);
				color: var(--text-color);
				width: 15px;
				margin-right: 5px;

				path {
					fill: var(--text-color);
				}
			}
		}

		.btn-secondary {
			background: var(--authTabBtn);
			border-color: var(--authTabBtn);
			color: var(--white);
			min-height: 2.375rem;
			width: 100%;
			font-size: 0.74rem;
			margin: 0 0.313rem;
			padding: 0.25rem 0.625rem;
			box-shadow: none;

			@media (max-width: 767px) {
				min-width: 140px;
			}

			&.active,
			&:hover {
				border-color: var(--secondaryBtnBorder);
				background: var(--inputbg);
				color: var(--secondaryBtnBorder);
			}
		}

		& > a {
			width: 100%;
			color: var(--white);
			text-align: center;
			font-size: 0.74rem;
			background: var(--authTabBtn);
			min-height: 2.375rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			margin: 0 0.313rem;
			border: 1px solid var(--authTabBtn);
			font-weight: bold;

			&.active {
				color: var(--text-color);
				text-shadow: var(--text-shadow);
			}

			&:hover {
				background: var(--authTabBtn);
				border-color: var(--authTabBtn);
				color: var(--white);
			}
		}

		.chat-close {
			background: transparent;
			padding: 0;
			margin: 0;
			width: 5rem;
			align-items: center;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			border: none;
			position: relative;

			&.active,
			&:hover {
				background: transparent;
				border-color: transparent;
			}
		}

		.chat-collapse-icon {
			background: transparent;
			padding: 0;
			margin: 0;
			width: 5rem;
			align-items: center;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			border: none;
			position: relative;

			&.active,
			&:hover {
				background: transparent;
				border-color: transparent;
			}

			@media (max-width: 991px) {
				display: none;
			}
		}

		.dropdown-toggle {
			&::after {
				display: inline-block;
			}
		}

		.dropdown-menu {
			min-width: 100%;

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.75rem;
			}
		}
	}

	.chat-content-scroll {
		height: calc(100vh - 200px);
		overflow-y: auto;

		@media (max-width: 1199px) {
			height: calc(100vh - 270px);
		}

		@media (max-width: 767px) {
			height: calc(100vh - 260px);
		}

		.empty-chat {
			width: 100%;
			padding: 5px;
		}

		.chat-content-wrap {
			padding: 0.313rem;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			@media (max-width: 767px) {
				width: 100%;
				height: calc(100vh - 260px);
			}

			.chat-card {
				margin: 0.313rem 0;
				border-radius: 8px;
				background: var(--inputbg);
				padding: 0.625em;
				color: var(--white);
				word-break: break-word;

				h4 {
					font-weight: 600;
					padding-right: 0.313rem;
				}

				p {
					color: var(--cryptoText);
				}

				p,
				h4 {
					display: inline-block;
					margin: 0;
					font-size: 0.75rem;
				}

				.user-icon {
					width: 1rem;
					margin-right: 0.313rem;
				}

				&:first-child {
					margin-top: auto;
				}
			}
		}
	}

	.chat-bottom-section {
		padding: 0.313rem 0.313rem;

		.chat-typing {
			input {
				background: var(--inputbg);
				height: 2.5rem;
				font-size: 0.74rem;
			}
		}

		.chat-details-wrap {
			margin: 0.625rem 0;

			& .btn {
				min-height: auto;
				padding: 0 0.5rem;
				font-size: 0.74rem;
				border-radius: 0.313rem;
			}

			& img {
				margin-right: 0.313rem;
				width: 1.5rem;
			}

			a {
				color: var(--white);
				font-weight: 600;
				font-size: 0.75rem;

				i {
					margin-right: 0.313rem;
				}
			}
		}
	}
}

.account-btn-wrap {
	display: ruby;
	button {
		border-radius: 5px !important;
	}

	.balance-btn {
	}

	.account-btn {
		img {
			width: 33px;
			margin-right: 0;
		}

		@media (max-width: 767px) {
			justify-content: center;
		}
	}

	&::after {
		display: none;
	}

	.dropdown-icon {
		width: 1.375rem;
		// height: 1.375rem;
		align-items: center;
		justify-content: center;
		border-radius: 0.313rem;

		& i {
			font-size: 0.75rem;
		}
	}
}

.header-user-menu {
	.header-wallet-section {
		display: flex;
	}

	.user-section-list {
		background-color: var(--socialLinksBg);
		// padding: 1rem;
		margin: 0;
		gap: 0.5rem;
		display: flex;
		flex-direction: column;

		li {
			padding: 1rem;
			margin: 0 1rem;
			background-color: var(--active-bg);
			border-radius: 8px;

			&:nth-child(1) {
				background-color: var(--socialLinksBg);
				padding: 0.5rem 0;
			}

			svg {
				width: 15px;
			}

			a {
				padding: 0;
				color: var(--white);
				display: flex;
				gap: 10px;
				align-items: center;
				font-size: 0.875rem;

				&.active {
					color: var(--text-color);
					text-shadow: var(--text-shadow);
					background-color: transparent;

					svg {
						color: var(--text-color);
						filter: var(--drop-shadow);

						path {
							fill: var(--text-color);
						}
					}
				}

				&:hover {
					background-color: transparent;
				}
			}
		}

		.user-name {
			h4 {
				font-size: 14px;
			}
		}
	}

	.MuiPaper-root {
		background-color: transparent;
		top: 58px !important;
		left: auto !important;
		right: 15px !important;
	}
}

.toggleButton {
	background-color: var(--socialLinksBg);
	border-radius: 8px;
	min-height: 40px;
	padding: 0 10px;
	align-items: center;
	justify-content: center;

	.MuiFormControlLabel-label {
		color: var(--white);
		font-size: 12px;
		font-weight: bold;
	}

	.MuiSwitch-sizeMedium {
		width: 55px;
		height: 28px;
		margin: 0 8px 0 0;
	}

	.MuiSwitch-thumb {
		height: 25px;
		width: 25px;
	}

	.MuiFormControlLabel-root {
		margin: 0;
	}
}

.chat-icon-button {
	padding: 8px 8px !important;
}

@media (max-width: 768px) {
	.chat-icon-button {
		display: none !important;
	}
}
