.error-msg {
	text-transform: capitalize;
	font-size: 11px;
	padding-top: 0.3rem;
}

.color-black {
	color: black !important;
	fill: black !important;
}

.mh-50 {
	min-height: 350px;
}

.float-right {
	float: right !important;
}

.btn {
	&.btn-danger {
		background-color: var(--button-danger);
	}
}

button,
a {
	&.btn {
		border-radius: 0.5rem;
		line-height: 27px;
		min-height: 40px;
		font-weight: 600;
		font-size: 0.874rem;
		padding: 0.25rem 1.25rem;

		&:active {
			opacity: 0.8;
			transform: scale(0.98);
		}

		&:focus {
			box-shadow: none;
		}
	}

	color: var(--white);

	&.btn-primary {
		background: var(--secondaryRadialBtn);
		border-color: var(--secondry-border);
		box-shadow:
			rgba(255, 176, 25, 0.4) 0px 0px 10px,
			rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
			rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
			rgb(255, 135, 25) 0px 0px 15px inset;
		color: var(--button-text);

		&:hover,
		&:active {
			background: var(--secondaryRadialBtn);
			border-color: var(--secondry-border);
			color: var(--button-text);
			box-shadow: none;
		}

		&:focus {
			background: var(--secondaryRadialBtn);
			border-color: var(--secondry-border);
			color: var(--button-text);
			box-shadow: none;
		}
	}

	&.btn-secondary {
		background: var(--secondaryBtn);
		border: none;
		box-shadow:
			rgba(255, 176, 25, 0.4) 0px 0px 10px,
			rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
			rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
			rgb(255, 135, 25) 0px 0px 15px inset;
		color: var(--button-text);

		&:hover,
		&:focus,
		&:active {
			background: var(--secondaryRadialBtn);
			border-color: var(--secondry-border);
			color: var(--button-text);
			box-shadow: none;
		}
	}

	&.btn-simple {
		background: var(--primaryDark);
		color: var(--white);

		&:hover,
		&:active {
			color: var(--white);
			background: var(--primaryDark);
		}
	}
}

.theme-table {
	color: var(--white);
	border-collapse: separate;
	border-spacing: 0 0.625rem;
	vertical-align: middle;

	thead {
		background: var(--themeCard);

		th {
			padding: 0.7rem 0.938rem;
			color: var(--authSocailTextColor);
			font-size: 14px;
			font-weight: 600 !important;
			// text-transform: uppercase;
			border: none;

			@media (max-width: 767px) {
				font-size: 0.75rem;
			}

			&:first-child {
				border-top-left-radius: 0.5rem;
				border-bottom-left-radius: 0.5rem;
			}

			&:last-child {
				border-top-right-radius: 0.5rem;
				border-bottom-right-radius: 0.5rem;
			}
		}
	}

	tbody {
		tr {
			td {
				border: none;
				background: var(--theme-dark);
				font-weight: 600;
				padding: 0.6rem 0.8rem;
				font-size: 13px;
				text-align: center;
				@media (max-width: 767px) {
					font-size: 0.875rem;
				}

				&:first-child {
					border-top-left-radius: 0.5rem;
					border-bottom-left-radius: 0.5rem;
				}

				&:last-child {
					border-top-right-radius: 0.5rem;
					border-bottom-right-radius: 0.5rem;
				}
			}
		}
	}
}
//
.verification-icon {
	width: 50px;
	margin-left: 0.5rem;
}
.signUp-modal-content {
	padding: 40px 50px !important;
}

.home-banner,
.casino-banner {
	//background: url('../../../public/assets/images/homePage/Banner 1.png');
	background-size: cover;
	background-position: 100% 100%;
	min-height: 336px;
	width: 100%;
	background-repeat: no-repeat;
	overflow: hidden;
	margin-bottom: 1.5rem;
	display: flex;
	align-items: center;
	position: relative;
	border-radius: 8px;

	@media (max-width: 1200px) {
		justify-content: center;
	}

	@media (max-width: 767px) {
		background-position: 91% 100%;
		background-size: cover;
		position: relative;
		border-radius: 8px;
	}

	&:before {
		// background: var(--overlayBg);
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		display: none;
		border-radius: 8px;

		@media (max-width: 1199px) {
			display: block;
		}
	}

	.home-banner-content {
		padding-left: 6.125rem;
		text-align: center;

		@media (max-width: 1199px) {
			padding: 0.625rem;
			text-align: center;
			position: relative;
		}

		h1 {
			color: var(--white);
			font-size: 1.5rem;
			line-height: 2.25rem;
			font-weight: 700;
			margin: 1.875rem 0;

			@media (max-width: 991px) {
				font-size: 1rem;
				line-height: 1.25rem;
			}

			br {
				@media (max-width: 1199px) {
					display: none;
				}
			}
		}

		.auth-social-listing-wrap {
			@media (max-width: 991px) {
				margin-top: 1rem;
				justify-content: center;
			}
		}

		.auth-social-card {
			margin: 0 0.313rem;

			@media (max-width: 767px) {
				width: 2.5rem;

				& img {
					width: 100%;
				}
			}
		}

		.btn-secondary {
			min-width: 9.188rem;
			margin-right: 0.313rem;
			min-height: 2.938rem;
			background: var(--secondaryRadialBtn);
			color: var(--button-text);
		}

		.banner-btn-wrap {
			justify-content: center;

			@media (max-width: 1200px) {
				justify-content: center;
			}

			@media (max-width: 991px) {
				justify-content: center;
			}
		}

		& svg {
			width: 14.688rem;
		}
	}
}

.casino-banner {
	// background: url('../../../public/assets/images/homePage/Banner 1.png');
	background-size: cover;
	background-position: 80% 100%;
}

.form-control {
	&::placeholder {
		color: var(--authSocailTextColor) !important;
	}

	&:focus {
		// border-color: var(--secondaryBtnBorder) !important;
		background: var(--inputbg);
		border-color: var(--text-color);
		box-shadow: var(--input-shadow) !important;
		-webkit-box-shadow: 0 0 0 1000px var(--inputbg) inset !important;
		-webkit-text-fill-color: var(--white);
		// border: 2px solid var(--secondaryBtnBorder);
		caret-color: var(--white);
	}
}

.content-container {
	width: 100%;
	padding: 1.5rem 1.5rem 0;
	margin: var(--headerHeight) auto 0;

	&.custom-spacer {
		max-width: var(--containerWidth);
	}

	&.chat-parent {
		padding-left: 240px;
		padding-right: 340px;
	}

	&.sportsbookwrap {
		padding: 0;
	}

	@media screen and (max-width: 767.98px) {
		padding: 1.5rem 1rem 0;
	}
}

.content-left-align {
	margin-left: 0;
	transition: 300ms all ease-in-out;
}

.theme-dropdown-menu {
	background-color: var(--theme-dark);
	min-width: 13.438rem;
	padding: 0;
	margin-top: 0.625rem !important;
	border: 1px solid var(--profileMenuBorder);
	border-radius: 8px;
	overflow: hidden;

	li {
		margin: 0.313rem 0.625rem;
		border-radius: 8px;

		a {
			color: var(--authTabBtnText);
			font-size: 0.875rem;
			padding: 0.625rem;
			font-weight: 500;
			border-radius: 8px;

			&:hover {
				background-color: transparent;
				color: var(--white);
			}
		}

		&:hover {
			background-color: var(--profileHover);
		}
	}
}

.custom-radio-wrap {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	.custom-radio-btn {
		position: absolute;
		top: 0;
		left: 0;
		height: 18px;
		width: 18px;
		background-color: var(--inputbg);
		border-radius: 50%;

		&:after {
			content: '';
			position: absolute;
			display: none;
			top: 5px;
			left: 5px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: var(--inputbg);
		}
	}

	&:hover input ~ .custom-radio-btn {
		background-color: var(--inputbg);
	}

	& input:checked ~ .custom-radio-btn {
		background-color: var(--authTabActiveBorder);

		&:after {
			display: block;
		}
	}
}

.profile-tabs {
	margin-bottom: 0.625rem;

	@media (max-width: 1199px) {
		width: 100% !important;
		flex-direction: row;
		overflow-x: auto;
		white-space: nowrap;
		flex-wrap: nowrap;
	}

	.nav-link {
		background: var(--profileCard);
		margin: 0;
		min-width: auto;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: var(--white);
		font-weight: 600;
		border-radius: 8px;
		// border-radius: 0px;
		border: 1px solid transparent;
		line-height: 11px;
		font-size: 0.875rem;
		padding: 1rem;

		@media (max-width: 1199px) {
			min-width: auto;
		}

		&.active,
		&:hover {
			background: var(--profileCard);
			border-color: transparent;
		}

		&.active {
			color: var(--text-color);
			font-weight: 600;
			// text-shadow: var(--text-shadow);

			svg {
				color: var(--text-color);
				filter: var(--drop-shadow);

				path {
					fill: var(--text-color);
				}
			}
		}

		svg {
			margin-right: 0.625rem;
			width: 15px;
		}
	}
}

.no-visibility {
	visibility: hidden !important;
}

.casino-search {
	padding-bottom: 1rem;

	.casino-search-content {
		position: relative;

		input {
			background-color: var(--casino-input);
			border-radius: 8px;
			height: 45px;
			border: 1px solid var(--casino-input);
			padding-left: 3rem;

			&::placeholder {
				color: var(--authSocailTextColor) !important;
			}

			// &:focus {
			// 	border-color: var(--text-color);
			// 	box-shadow: var(--input-shadow) !important;
			// }
		}

		.search-icon {
			position: absolute;
			left: 0.625rem;
			top: 0.8rem;

			path {
				fill: var(--search-icon--color);
			}
		}
	}

	.btn-close {
		position: absolute;
		right: 0.625rem;
		top: 0.725rem;
		filter: invert(50);
		opacity: 1;
	}
}

@keyframes skeleton-glitter {
	to {
		background-position: -100% 0;
	}
}

.game-card-skeleton {
	// border-radius: 1rem;
	// background: linear-gradient(120deg,var(--skeleton-base-color) 30%,var(--skeleton-shine-color) 38%,var(--skeleton-shine-color) 40%,var(--skeleton-base-color) 48%) 100% 0;
	// background-size: 200% 200%;
	// animation: skeleton-glitter 2s infinite
}

.btn-close {
	opacity: 1 !important;
	border: none !important;
}

.modal {
	backdrop-filter: blur(2px) brightness(0.5);
	-webkit-backdrop-filter: blur(2px) brightness(0.5);
	z-index: 1200;
}

.golabl-casino-section {
	padding-bottom: 1.875rem;

	& .provider-text {
		font-weight: 800;
		font-size: 1rem;
	}

	.theme-card-wrap {
		height: 100%;
		&:hover {
			transition: 200ms all ease-in-out;
			transform: translateY(-0.2rem);
		}
		padding-top: 0.25rem;
		.theme-card {
			background: var(--themeCard);
			border-radius: 0.5rem;
			overflow: hidden;
			padding: 0;
			transition: 200ms all ease-in-out;
			height: 100%;

			.casino-img-wrap {
				width: 100%;
				height: 100%;
				background: linear-gradient(
						120deg,
						var(--skeleton-base-color) 30%,
						var(--skeleton-shine-color) 38%,
						var(--skeleton-shine-color) 40%,
						var(--skeleton-base-color) 48%
					)
					100% 0;
				background-size: 200% 200%;
				border-radius: inherit;
				margin-top: 0;
				cursor: pointer;
				// padding-bottom: 110%;
				position: relative;
				transition: 200ms all ease-in-out;
				animation: skeleton-glitter 2s infinite;
				aspect-ratio: 1/1;

				img {
					border-radius: inherit;
					// position: absolute;
					// top: 0px;
					// left: 0px;
					width: 100%;
					height: 100%;
					border-radius: 8px;
					filter: contrast(100%) saturate(1.2);
					cursor: pointer;
					transition: 200ms all ease-in-out;
				}

				.card-overlay {
					width: 100%;
					height: 100%;
					background: var(--overlayBg);
					border-radius: inherit;
					color: var(--white);
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					overflow: hidden;
					opacity: 0;
					position: absolute;
					transform: translateY(-100%);
					transition: 200ms all ease-in-out;

					h4 {
						font-size: 0.875rem;
						margin: 0;
						text-transform: uppercase;
						// font-family: 'Rubik', sans-serif;
						font-weight: 700;
						margin-bottom: 0.313rem;

						@media (max-width: 1199px) {
							font-size: 0.675rem;
							text-align: center;
						}

						@media (max-width: 1199px) {
							font-size: 0.875rem;
						}
					}

					p {
						font-size: 0.75rem;
						margin: 0;
						font-weight: 400;
						margin-bottom: 0.313rem;
					}
				}
			}

			&:hover {
				.casino-img-wrap {
					.card-overlay {
						opacity: 1;
						transform: translateY(-100%);
					}
				}
			}
		}
	}
}

@keyframes overlayEffect {
	0% {
		transform: scale(1.05);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes accordianEffect {
	0% {
		transform: scale(1.02);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.rc-tooltip-inner {
	background-color: var(--darkGray) !important;
	border-color: var(--darkGray) !important;
	color: var(--white) !important;
	border-radius: 8px !important;
	font-size: 0.74rem;
}

.rc-tooltip {
	width: auto !important;
}

.rc-tooltip-placement-left {
	.rc-tooltip-arrow {
		border-left-color: var(--darkGray) !important;
		right: -4px !important;
	}
}

.rc-tooltip-placement-top {
	.rc-tooltip-arrow {
		border-top-color: var(--darkGray) !important;
	}
}

.animated-table {
	position: relative;

	&::after {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 80px;
		content: '';
		background: var(--table-fade);
		width: 100%;

		@media (max-width: 1024px) {
			display: none;
		}
	}

	.table-head {
		position: relative;
		z-index: 2;
	}

	.table-animation:nth-of-type(2n + 1) {
		animation: 500ms ease-out 0s 1 normal none running tAnimation;
	}

	.table-animation:nth-of-type(2n) {
		animation: 500ms ease-out 0s 1 normal none running hideAnimation;
	}
}

@keyframes hideAnimation {
	0% {
		transform: translateY(-100%);
	}
}

@keyframes tAnimation {
	0% {
		transform: translateY(-100%);
	}
}

#bt-inner-page {
	position: relative;
	z-index: 1;
}
.selectProvider__value-container {
	display: flex !important;
	flex-wrap: nowrap !important;
	// overflow-x: scroll !important;
	& .selectProvider__placeholder {
		font-size: 13px;
		color: var(--authSocailTextColor) !important;
		font-weight: 500;
	}
	& .selectProvider__multi-value {
		min-width: max-content !important;
		& .selectProvider__multi-value__remove:hover {
			background-color: var(--muted);
		}
	}
}

.slide-wrap {
	position: relative;

	&.providers-slide {
		.slick-arrow {
			@media (max-width: 767px) {
				top: -39px;
			}
		}
	}

	.slick-arrow {
		position: absolute;
		top: -40px;
		right: 0;
		border-radius: 7px;
		background: var(--themeCard);
		border: 1px solid var(--slider-btnBorder);
		padding: 0.313rem 0.625rem;
		font-size: 0;
		min-width: 32px;
		min-height: 24px;

		@media (max-width: 1024px) {
			top: -48px;
		}

		&.slick-prev {
			right: 42px;
		}

		&.slick-prev:before {
			position: absolute;
			left: 12px;
			top: 6px;
			background-position: center;
			content: '';
			background: url('../../../src/components/ui-kit/Icons/svg/slider-left-arrow.svg');
			background-repeat: no-repeat;
			font-size: 0;
			height: 15px;
			width: 8px;
			background-size: 6px;
		}

		&.slick-next {
			right: 0px;
		}

		&.slick-next:before {
			position: absolute;
			left: 12px;
			top: 6px;
			content: '';
			background: url('../../../src/components/ui-kit/Icons/svg/slider-right-arrow.svg');
			background-repeat: no-repeat;
			font-size: 0;
			height: 15px;
			width: 8px;
			background-size: 6px;
		}
	}

	.slick-disabled {
		opacity: 0.4;
	}
}

.theme-divider {
	background-color: var(--authSocailTextColor);
	margin: 0.313rem auto;
	width: 90%;
}

.live-wins {
	padding-bottom: 2.2rem;

	.live-card {
		text-align: center;
		margin: 0 0.313rem;

		.live-img {
			height: 75px;
			width: 75px;
			overflow: hidden;
			border-radius: 0.25rem;
			cursor: pointer;

			& img {
				object-fit: fill;
				height: 100%;
				width: 100%;
			}
		}

		p {
			font-size: 0.625rem;
			font-weight: 500;
			line-height: 1rem;
			color: var(--white);
			margin: 0.313rem 0 0;
		}

		span {
			color: var(--liveGreen);
			font-size: 0.725rem;
			font-weight: 400;
			line-height: 1.125rem;
			display: block;
		}
	}

	.live-card-wrap {
		overflow-x: hidden;
		white-space: nowrap;
	}
}

.inner-heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.625rem;
	min-height: 35px;

	h4 {
		font-size: 0.875rem;
		height: 15px;
		font-weight: 600;
		color: var(--white);
		text-transform: capitalize;
		margin: 0 auto 0 0;

		@media screen and (max-width: 500px) {
			max-width: 100px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow-x: hidden;
			font-size: 0.75rem;
			overflow-y: hidden;
		}
	}

	img {
		margin-right: 0.625rem;
	}

	.week-wrap {
		a {
			background: var(--weekBg);
			border-radius: 0.438rem;
			color: var(--white);
			font-size: 0.875rem;
			font-weight: 500;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0.313rem;
			min-height: 24px;
			min-width: 24px;
		}
	}

	.inner-heading-text-box {
		width: calc(100% - 5.5rem);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	@media screen and (max-width: 767.98px) {
		margin-bottom: 0.625rem;
	}
}

.heading-link {
	color: var(--headingLink);
	font-size: 14px;
	font-weight: 600;
	// line-height: 0.6rem;
	margin-left: auto;

	&:hover {
		color: var(--secondaryBtn);
		// text-decoration: underline;
	}
}

.reset-password {
	color: var(--headingLink);

	&:hover {
		text-decoration: underline;
	}
}

.provider-section {
	padding-bottom: 1.875rem;

	.theme-card {
		height: 100%;
		padding: 1.5rem;
		background: var(--providerCard);
		text-align: center;
		min-height: 132px;
		border-radius: 8px;
		border: none;
		cursor: pointer;

		@media (max-width: 767px) {
			padding: 0.5rem;
		}

		.provider-logo {
			height: 60px;
			margin-bottom: 0.625rem;
			display: flex;
			align-items: center;
			justify-content: center;

			@media (max-width: 767px) {
				width: 100px;
				margin: 0 auto;
			}

			img {
				width: 100%;
			}
		}

		.provider-badge-wrap {
			background: var(--authTabActiveBorder);
			border-radius: 1.063rem;
			padding: 0.313rem 0.625rem;
			color: var(--white);
			text-align: center;
			font-size: 0.75rem;
			display: inline-block;
			margin-top: 1rem;
		}
	}
}

.table-section {
	.heading-right-listing {
		margin-right: 0;

		a {
			color: var(--authSocailTextColor);
			padding: 0.313rem 0.625rem;
			border-radius: 0.5rem;
			font-size: 0.75rem;
			font-weight: 500;
		}

		li {
			margin: 0 0.313rem;

			&.active {
				a {
					background: var(--weekBg);
					color: var(--white);
				}
			}

			&:hover {
				a {
					background: var(--weekBg);
				}
			}
		}

		.nav-tabs {
			border: none;
		}
	}

	.text-success {
		color: var(--liveGreen) !important;
	}

	.user-details {
		.user-image {
			height: 2.313rem;
			width: 2.313rem;
			border-radius: 0.5rem;
			background: var(--inputBorder);

			& img {
				width: 100%;
			}
		}
	}
}

.heading-right-listing {
	margin-right: 0;
	margin-left: auto;

	@media (max-width: 767px) {
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
		padding-bottom: 0.8rem;
	}

	a {
		color: var(--authSocailTextColor);
		padding: 0.313rem 0.625rem;
		border-radius: 0.5rem;
		font-size: 0.75rem;
		font-weight: 500;
	}

	li {
		margin: 0 0.313rem;

		&.active {
			a {
				background: var(--weekBg);
				color: var(--white);
			}
		}

		&:hover {
			a {
				background: var(--weekBg);
			}
		}
	}

	.nav-tabs {
		border: none;
	}
}

.provider-list {
	background: var(--authTabBtn);
	border-radius: 8px;
	max-height: 400px;
	max-width: 170px;
	overflow-x: hidden;
	overflow-y: auto;
	padding-top: 0;

	.dropdown-item {
		color: var(--authTabBtnText);
		font-size: 0.75rem;
		padding: 0.56rem 1rem;
		border-radius: 0.375rem;
		color: var(--white);

		&:hover,
		&.active {
			background: var(--theme-dark);
		}
	}

	.casino-search {
		padding: 0.625rem;
		position: sticky;
		top: 0;
		background: var(--authTabBtn);

		input {
			padding-left: 2rem;
			font-size: 0.874rem;
		}

		.search-icon {
			width: 1.1rem;
			height: 1.1rem;
			top: 0.9rem;
		}
	}

	.provider-list-wrap {
		padding: 0.313rem;
	}
}

.search-filter-right {
	.btn-primary {
		font-size: 0.875rem;
		height: 45px;

		span {
			color: var(--authTabActiveBorder);
		}
	}

	@media (max-width: 767px) {
		justify-content: space-between;

		& .btn {
			width: 100%;
		}
	}
}

.slider-arrow-placeholder {
	display: flex;
	justify-content: flex-end;
	height: 24px;
	opacity: 0.3;
	display: none;

	@media (max-width: 767px) {
		display: none;
	}

	span {
		border-radius: 7px;
		background: var(--themeCard);
		border: 1px solid var(--slider-btnBorder);
		padding: 0.313rem 0.625rem;
		font-size: 0;
		min-width: 36px;
		min-height: 25px;
		display: block;
		margin-left: 0.4rem;

		& svg {
			height: 15px;
			width: 8px;
		}
	}
}

.color-red {
	color: var(--loss);
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px var(--inputbg) inset !important;
	-webkit-text-fill-color: var(--white);
	// border: 2px solid var(--secondaryBtnBorder);
	caret-color: var(--white);
}

.search-game-list {
	overflow: auto;
	display: grid;
	align-items: stretch;
	grid-template-columns: repeat(auto-fill, minmax(13%, 1fr));
	gap: 18px 12px;
}

.page-not-found {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 75vh !important;
}

.limit-container {
	.form-control {
		display: unset;
		max-width: 150px;
		max-height: 45px;
		margin-left: 5px;
	}

	.btn-days {
		color: azure;
	}
}

.limit-dialog-modal {
	.MuiDialog-paper {
		background-color: var(--primaryDark);
		color: white;

		.limit-modal-close-button {
			background-color: transparent;
			border: none;
			outline: none;
			color: white;
			cursor: pointer;
			position: absolute;
			right: 10px;
		}
	}
}

.btn {
	&.btn-days {
		&.active {
			background: var(--secondaryRadialBtn);
			border-color: var(--secondry-border);
			box-shadow:
				rgba(255, 176, 25, 0.4) 0px 0px 10px,
				rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
				rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
				rgb(255, 135, 25) 0px 0px 15px inset;
			color: var(--button-text);
		}
	}
}

.card-grid {
	display: grid;
	-webkit-box-align: stretch;
	align-items: stretch;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	gap: 18px 12px;
}
@media (max-width: 1200px) {
	.card-grid {
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	}
}
@media (max-width: 900px) {
	.card-grid {
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	}
}

@media (max-width: 600px) {
	.card-grid {
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	}
}

.game-image-wrapper {
	display: flex;
	justify-content: center;
	height: 100%;
	align-items: center;
	.Game-count {
		position: absolute;
		right: 0;
		top: 3%;
		right: 2%;
		font-size: 10px;
		font-weight: 600;
		border-radius: 12px;
		color: #fff;
		white-space: nowrap;
		padding: 0.1rem 0.3rem;
		width: max-content;
		background-color: #00000059;
		& svg {
			width: 0.9rem;
		}
	}
}

.css-10rafov-control {
	border-color: var(--text-color) !important;
	box-shadow: var(--input-shadow) !important;
}

.css-10rafov-control:hover {
	border-color: var(--text-color) !important;
}

.css-dvyn1m-control {
	border-color: var(--inputBorder) !important;
}

.css-dvyn1m-control,
.css-10rafov-control {
	min-height: 50px !important;
	border-radius: 8px !important;
}

input::placeholder {
	color: var(--authSocailTextColor) !important;
	font-size: 13px !important;
	font-weight: 500;
}

.css-1u9des2-indicatorSeparator {
	// background-color: var(--inputBorder) !important;
}

.horizontal-theme-tabs svg {
	color: var(--white);
}

.progress-bar {
	background-color: var(--secondaryBtnBorder);
}

.css-12a83d4-MultiValueRemove:hover {
	background-color: transparent !important;
	color: var(--white) !important;
}

.css-s6jmc3-multiValue {
	background-color: var(--casino-input) !important;
	border-radius: 3px;
}

.css-wsp0cs-MultiValueGeneric {
	color: var(--white) !important;
}

.MuiStepLabel-root {
	.MuiStepLabel-iconContainer {
		&.Mui-active {
			svg {
				color: var(--secondaryBtn) !important;

				.MuiStepIcon-text {
					fill: var(--button-text) !important;
				}
			}
		}
	}
}

.no-games {
	background-color: var(--casino-input);
	display: inline-block;
	width: 100%;
	border-radius: 8px;
}

button[disabled] {
	color: #fff !important;
	background-color: #6c757d !important;
	border-color: #6c757d !important;
	box-shadow: inherit !important;
}

.loader-parent {
	height: 100vh;
	width: 100%;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	.full-body-loader {
		flex-direction: column;
		position: relative;
		display: flex;
		align-items: center;

		img {
			z-index: 9;
			width: 6%;
			text-shadow: var(--text-shadow);
			filter: var(--drop-shadow);
			color: var(--text-color);
		}

		span {
			font-size: 25px;
			font-weight: bold;
			color: var(--text-color);
			text-shadow: var(--text-shadow);
			position: absolute;
			bottom: -25px;
		}
	}
}

// Table design code
table {
	width: 100%;
	// border-radius: 1.25rem;
	border-radius: 0.75rem;
	overflow: hidden;
}

thead tr {
	// background: var(--secondaryBtnBorder);
}

thead tr th {
	background: var(--secondaryBtnBorder);
	border-bottom: 0;
	color: var(--button-text);
	font-size: 1rem;
	font-weight: 600 !important;
	white-space: nowrap;
	text-align: center;
	padding: 1rem;
}

tbody tr td {
	border-bottom: 0;
	color: var(--white);
	font-size: 1rem;
	font-weight: 500;
	padding: 1rem;
}

tbody tr {
	background: var(--socialLinksBg);
}

tbody tr:nth-child(even) {
	background: var(--theme-dark);
}

tbody:is(.no-data-msg-box) .no-data-msg-box {
	background-color: var(--theme-dark);
	color: #9eabff;
	font-size: 1.5rem;
	font-weight: 500;
	text-transform: capitalize;
	text-align: center;
	padding: 1rem;
}

.wagerSection {
	.wagerInputs {
		input {
			height: 45px;
			width: 100%;
			background-color: var(--casino-input);
			border-radius: 8px;
			border: 1px solid var(--casino-input);
			padding: 10px;
			margin: 10px 0;
			color: var(--white);
			padding-left: 60px;

			&::placeholder {
				color: var(--white);
			}

			&:focus {
				border-color: var(--text-color);
				box-shadow: var(--input-shadow);
			}

			&:focus-visible {
				outline: none;
			}
		}

		.inputIcon {
			position: relative;

			p {
				position: absolute;
				left: 25px;
				top: 23px;
				color: var(--white);
				// @media screen and (max-width: '768px'){
				//   left: 15px;
				// top: 32%;
				// }
			}
			.MuiSvgIcon-root {
				position: absolute;
				right: 11px;
				top: 21px;
			}
		}

		.limitExpiry {
			position: absolute;
			bottom: -8px;
			font-size: 11px;
		}
	}
}

.transaction-tab {
	fieldset {
		border: none;
	}
}
