.profile-inner-section {
	.section-heading {
		margin-top: 20px;
		color: var(--white);
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: 1.5rem;
	}

	.profile-inner-wrap {
		display: flex;
		justify-content: space-between;
		margin-left: -0.75rem;
		margin-right: -0.75rem;

		.tabs-content-wrap {
			.tabs-content-box {
				background-color: var(--darkGray);
				border-radius: 8px;
				-webkit-border-radius: 8px;
				-moz-border-radius: 8px;
				-ms-border-radius: 8px;
				-o-border-radius: 8px;
				padding: 1rem;

				.nav {
					width: fit-content;
					background-color: var(--primaryDark);
					border-radius: 1.5rem;
					-webkit-border-radius: 1.5rem;
					-moz-border-radius: 1.5rem;
					-ms-border-radius: 1.5rem;
					-o-border-radius: 1.5rem;
					display: flex;
					overflow-x: auto;
					overflow-y: hidden;
					scroll-snap-type: x mandatory;
					padding: 0.25rem;
					margin: 0 0 1rem;

					&::-webkit-scrollbar {
						width: 0;
						height: 0;
					}
				}

				.content-box {
					form {
						&:not(:last-child) {
							margin-bottom: 1rem;
						}
					}
				}
			}
		}

		@media screen and (max-width: 991.98px) {
			& {
				flex-direction: column;

				.tabs-nav-wrap {
					.nav {
						position: static;
					}
				}

				.tabs-content-wrap {
					width: 100%;
					margin-top: 1rem;
				}
			}
		}
	}
}

.filter-section {
	.select-box {
		height: 40px;
		width: 150px;
		background-color: var(--profileCard);
		color: var(--white);
		border: none;
	}
}

.tableContainer {
	.MuiPaper-root {
		box-shadow: none;
	}

	table thead tr th {
		color: #fff;
		background: #121c27;
		border-bottom: 0;
		position: relative;
		z-index: 0;
		font-weight: 600;
		border-bottom: 1px solid #fff;
	}

	table tbody tr {
		background-color: #142230;
	}

	table tbody tr td {
		color: #fff;
		border-bottom: 1px solid #ffffff50;
	}
}

.transaction-type {
	.MuiInputBase-root:hover {
		border: transparent;
	}
}

#transaction-type {
	color: #fff;
	border: 1px solid #fff;
	background: var(--inputbg);
	padding: 12px;
	border: 1px solid var(--inputBorder);
	border-radius: 10px;
	height: 30px;
}

.trans-option {
	background-color: #142230;
	color: #fff;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
	background: var(--darkGray);
	color: white;
}

.trans-btn {
	background: var(--profileOutlineBtn);
	border: 1px solid var(--authTabActiveBorder);
	border-radius: 8px;
	color: var(--white);
	font-size: 0.875rem;
	font-weight: 600 !important;
	line-height: 1.125rem;
	max-height: 36px !important;
	margin-top: 25px;
}

.nav-item {
	.nav-link {
		background: var(--profileCard);
		margin: 0.313rem;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: flex-start;
		color: var(--authTabBtnText);
		font-weight: 500;
		border-radius: 8px;
		border: 2px solid transparent;
		font-size: 0.875rem;

		@media (max-width: 1199px) {
			min-width: auto;
		}

		&.active,
		&:hover {
			background: var(--profileCard);
			border-color: var(--authTabActiveBorder);
			color: var(--button-text) !important;
		}
	}
}

#menu- {
	.MuiPaper-elevation {
		background-color: transparent !important;
	}
}

.MuiMenu-list {
	background: var(--darkGray);
	color: white;
}

.box-view--display {
	.match-list--view {
		display: none;
	}
}

.list-view--display {
	.match-box--view {
		display: none;
	}
}
