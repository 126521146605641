.terms-n-condition {
	p {
		// font-family: 'Lato';
		font-style: normal;
		cursor: pointer;
		font-size: 15px;
		line-height: 18px;
		margin-bottom: 0 !important;
	}
}

.radioButton {
	color: var(--secondaryRadialBtn) !important;
}

.react-tel-input {
	.form-control {
		border: 1px solid var(--inputBorder) !important;
		background: #121c27 !important;
		height: 48px !important;
		box-shadow: none;
		border-radius: 8px !important;
	}

	.flag-dropdown {
		background-color: #121c27 !important;
		border-radius: 8px 0 0 8px !important;
		border: 1px solid var(--inputBorder) !important;
		border-right: 0 !important;

		&.open {
			background: #121c27 !important;

			.selected-flag {
				background: #121c27 !important;

				&:hover {
					background: #121c27 !important;
				}
			}
		}

		.selected-flag {
			&:hover {
				background: #121c27 !important;
				border-radius: 8px 0 0 8px !important;
			}
		}

		.country-list {
			background: #121c27 !important;

			.country {
				&:hover {
					background-color: #000000 !important;
				}

				&.highlight {
					background-color: #000000 !important;
				}
			}
		}

		.search {
			background: #121c27 !important;
		}
	}
}

.auth-logo {
	text-align: center;
	img {
		height: 80px;
		width: auto;
		text-align: center;
		margin-bottom: 10px;
	}
}

.error-message {
	font-size: 0.6875rem;
	color: #dc3545 !important;
}
.react-datepicker__input-container {
	input {
		width: 100%;
		background-color: var(--inputbg);
		border: 1px solid var(--inputBorder);
		color: var(--white);
		padding: 7px 12px;
		border-radius: 8px;
		height: 38px;
		font-size: 1rem;

		&:focus {
			color: var(--white);
		}
	}
}
