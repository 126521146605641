:root {
	--white: #ffffff;
	--black: #000000;
	--lightkGray: #a4a4a4;
	--gray: #879096;
	--light-gray: rgba(130, 154, 181, 1);
	--theme-dark: rgb(26, 29, 41);
	--theme-secondary: #2c4158;
	--theme-border: #25394f;
	--theme-border2: rgba(0, 0, 0, 0.35);
	--secondry-border: rgb(255, 232, 26);
	--button-text: rgb(20, 23, 34);
	--primaryBtn: linear-gradient(
		to right,
		rgba(20, 32, 46, 1) 0%,
		rgba(33, 50, 68, 1) 100%
	);
	--primaryBtnHover: linear-gradient(
		to right,
		rgba(33, 50, 68, 1) 0%,
		rgba(20, 32, 46, 1) 100%
	);
	--secondaryBtn: rgb(255, 232, 26);
	--secondaryRadialBtn: rgb(255, 232, 26);
	--secondaryBtnBorder: rgb(255, 232, 26);
	// --authTabActiveBorder: #21a8f7;
	// --placeBetBase: #0373b5;
	--authTabActiveBtnBg: #303f50;
	--authTabBtn: #202f3f;
	--authTabBtnText: #8a90a2;
	--authSocailColor: #344051;
	--authSocailTextColor: rgb(177, 182, 198);
	--inputbg: #121c27;
	--inputBorder: #223244;
	--weekBg: #1b2a39;
	--themeCardTopGradient: linear-gradient(
		180deg,
		rgba(68, 94, 122, 1) 0%,
		rgba(37, 57, 79, 0) 100%
	);
	--themeCard: linear-gradient(180deg, #213143 0%, #162331 100%);
	--sidebarMenuBg: linear-gradient(180deg, #162331 0%, #1f3246 100%);
	--liveMatchCountBg: linear-gradient(180deg, #273a4d 0%, #1a2835 100%);
	--liveMatchCountBorder: rgba(255, 255, 255, 0.04);
	--liveGreen: #72f238;
	--profitBg: #2a3c2f;
	--headingLink: rgb(255, 176, 24);
	// --sidebarHover: #75cdff;
	--slider-btnBorder: #24364a;
	--loss: #ff3636;
	--darkGray: #1a1d29;
	--table-fade: linear-gradient(
		to bottom,
		rgba(30, 87, 153, 0.01) 0%,
		rgba(15, 25, 35, 1) 100%
	);
	--nft-badge: #223345;
	--footerBorder: #323b45;
	--footerBg: #142230;
	--selectBg: rgba(49, 58, 66, 0.5);
	--footerLinking: #0f1b26;
	--socialLinksBg: #1e2d3d;
	--overlayBg: rgb(23 37 54 / 91%);
	--authPrimryBtn: radial-gradient(
		90.91% 90.91% at 50% 9.09%,
		#1b2a3c 0%,
		#131f2d 100%
	);
	--providerCard: linear-gradient(223deg, #162331 0%, #111d28 100%);
	--profileMenuTop: #1e2f41;
	--profileMenuBorder: #293c51;
	--profileHover: #111c27;
	--profileCard: #182533;
	--profileCardBorder: rgba(255, 174, 24, 0.281);
	// --statusColorOne: #be6bff;
	--profileSocialBg: #1d2b3b;
	--profileOutlineBtn: #080e14;
	--cryptoText: #90b4d9;
	// --sidebarBtnGradient: linear-gradient(90deg, #0096ff -1.3%, #48bdff 99.93%);
	--text-shadow: rgb(255, 93, 0) 0px 0px 8px;
	--text-color: rgb(255, 255, 193);
	--search-icon--color: rgb(94, 98, 111);
	--input-shadow: rgb(255, 252, 57) 0px 0px 1px inset,
		rgb(255, 93, 0) 0px 0px 4px;
	--active-bg: rgb(26, 29, 41);
	--sidebar-bg: rgb(31, 35, 48);
	--drop-shadow: drop-shadow(rgb(255, 93, 0) 0px 0px 6px);

	--button-danger: rgb(255, 73, 73);

	--mediumGrey: #7c7c7c;
	--shipGray: #3c3c3c;
	--table-gray: #272727;
	--primaryDark: #0f212e;
	--secondaryDark: #0f212e;
	--dark: #1c1e22;
	--lightDark: #31343a;

	--skeleton-base-color: #0f212e;
	--skeleton-shine-color: #004e5c59;

	--gradientHover: linear-gradient(
		270deg,
		rgba(7, 214, 241, 0.735) 0%,
		rgba(167, 36, 200, 0.216) 100%
	);
	--darkSlate: #1a2c38;
	--lightSlate: #081f30;
	--aquaBlue: #0387c3; // #016C92;
	--darkAqua: #0378ad;
	--denim: #3e7083;
	--red: #ff0000;
	--green: #00e701;
	--hoverGreen: #1fff20;
	--blackCat: #3f3f41;
	--cinder: #0a1924;
	--casal: #305167;
	--darkBlue: #0f181f;

	--gradientText: linear-gradient(165deg, #07d4f1, #0177fc);
	--blurGradient: linear-gradient(90deg, transparent, #0f181f);
	--modalBG: #0f212e;
	--modalInputBG: #222126;
	--modalInputFocus: linear-gradient(270deg, #aea127 0%, #016c92 100%);
	--inputBG: #0f181f;
	--inputText: #276895;
	--blueGrey: #b1bad3;
	--inputFocusBG: #0f181f;
	--inputFocusText: #8f8f8f;
	--login-input-label: #9d9d9d;
	--borderLight: rgba(157, 157, 157, 0.4);
	--borderMuted: #213743;
	--blackOpacity50: rgba(0, 0, 0, 0.5);
	--blackOpacity35: rgba(0, 0, 0, 0.35);
	--orange: #dc3545;
	--orangeHover: #ca2233;
	--gameBg: #091117;
	--activeBlue: #006b92;
	--btnBgGrey: #2f4553;
	--btnBgGreyHover: #3d5564;
	--muted: #b1bad3;
	--hoverBg: rgba(203, 215, 255, 0.075);

	--shadows-sm: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
	--shadows-md: 0 4px 6px -1px rgba(27, 23, 23, 0.2),
		0 2px 4px -1px rgba(0, 0, 0, 0.12);
	--shadows-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.2),
		0 4px 6px -2px rgba(0, 0, 0, 0.1);
	--shadows-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.2),
		0 10px 10px -5px rgba(0, 0, 0, 0.08);
	--shadows-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.4);

	--casino-filter-btn: rgba(203, 215, 255, 0.03);
	--casino-input: rgba(15, 17, 26, 0.55);

	// betslip color variables start here
	--betSlipLeftBg: #2b2e3b;
	--betSlipCloseBg: rgba(255, 255, 255, 0.1);
	--oddsValueBg: rgb(43, 46, 59);
	--liabilityColor: rgb(245 52 63);
	--profitColor: rgb(52 199 89);
	--payoutColor: rgb(124 130 132);

	// sportsBook variable start here
	--topNavigationBg: #2b2e3b;
	--extraMarketSportsBg: #171924;
	--sportsButtonBg: rgb(33, 37, 50);
	--button-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
		rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
		rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(255, 135, 25) 0px 0px 15px inset;
	--boxViewBg: #212532;
	--oddsParentBg: #2b2e3b;
	--backOddsBg: rgb(143 210 237);
	--layOddsBg: rgb(251 175 179);
	--textMaskBg: linear-gradient(90deg, rgb(0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
	--sportsBookBodyBg: rgb(23, 25, 36);

    --chatDrawerWidth: 380px;
	--pending: #ff9;
	--won: #23b14c;
	--loss: #ff2a04;
	--caseOut: #ffa500;

	// @media variables
	--mobile: 575px;
	--small: 600px;
	--medium: 991px;
	--large: 1200px;
	--extraLarge: 1536px;

	// *****************************************************
	// *****************************************************
	// *****************************************************
	// *****************************************************
	// *****************************************************

	--primaryMain-50: rgba(255, 232, 26, 0.05);
	--primaryMain-100: rgba(255, 232, 26, 0.1);
	--primaryMain-150: rgba(255, 232, 26, 0.15);
	--primaryMain-200: rgba(255, 232, 26, 0.2);
	--primaryMain-250: rgba(255, 232, 26, 0.25);
	--primaryMain-300: rgba(255, 232, 26, 0.3);
	--primaryMain-350: rgba(255, 232, 26, 0.35);
	--primaryMain-400: rgba(255, 232, 26, 0.4);
	--primaryMain-450: rgba(255, 232, 26, 0.45);
	--primaryMain-500: rgba(255, 232, 26, 0.5);
	--primaryMain-550: rgba(255, 232, 26, 0.55);
	--primaryMain-600: rgba(255, 232, 26, 0.6);
	--primaryMain-650: rgba(255, 232, 26, 0.65);
	--primaryMain-700: rgba(255, 232, 26, 0.7);
	--primaryMain-750: rgba(255, 232, 26, 0.75);
	--primaryMain-800: rgba(255, 232, 26, 0.8);
	--primaryMain-850: rgba(255, 232, 26, 0.85);
	--primaryMain-900: rgba(255, 232, 26, 0.9);
	--primaryMain-950: rgba(255, 232, 26, 0.95);
	--primaryMain-1000: rgba(255, 232, 26, 1);

	--veryPaleYellow-50: rgba(255, 255, 193, 0.05);
	--veryPaleYellow-100: rgba(255, 255, 193, 0.1);
	--veryPaleYellow-150: rgba(255, 255, 193, 0.15);
	--veryPaleYellow-200: rgba(255, 255, 193, 0.2);
	--veryPaleYellow-250: rgba(255, 255, 193, 0.25);
	--veryPaleYellow-300: rgba(255, 255, 193, 0.3);
	--veryPaleYellow-350: rgba(255, 255, 193, 0.35);
	--veryPaleYellow-400: rgba(255, 255, 193, 0.4);
	--veryPaleYellow-450: rgba(255, 255, 193, 0.45);
	--veryPaleYellow-500: rgba(255, 255, 193, 0.5);
	--veryPaleYellow-550: rgba(255, 255, 193, 0.55);
	--veryPaleYellow-600: rgba(255, 255, 193, 0.6);
	--veryPaleYellow-650: rgba(255, 255, 193, 0.65);
	--veryPaleYellow-700: rgba(255, 255, 193, 0.7);
	--veryPaleYellow-750: rgba(255, 255, 193, 0.75);
	--veryPaleYellow-800: rgba(255, 255, 193, 0.8);
	--veryPaleYellow-850: rgba(255, 255, 193, 0.85);
	--veryPaleYellow-900: rgba(255, 255, 193, 0.9);
	--veryPaleYellow-950: rgba(255, 255, 193, 0.95);
	--veryPaleYellow-1000: rgba(255, 255, 193, 1);

	--vividOrange-50: rgba(255, 93, 0, 0.05);
	--vividOrange-100: rgba(255, 93, 0, 0.1);
	--vividOrange-150: rgba(255, 93, 0, 0.15);
	--vividOrange-200: rgba(255, 93, 0, 0.2);
	--vividOrange-250: rgba(255, 93, 0, 0.25);
	--vividOrange-300: rgba(255, 93, 0, 0.3);
	--vividOrange-350: rgba(255, 93, 0, 0.35);
	--vividOrange-400: rgba(255, 93, 0, 0.4);
	--vividOrange-450: rgba(255, 93, 0, 0.45);
	--vividOrange-500: rgba(255, 93, 0, 0.5);
	--vividOrange-550: rgba(255, 93, 0, 0.55);
	--vividOrange-600: rgba(255, 93, 0, 0.6);
	--vividOrange-650: rgba(255, 93, 0, 0.65);
	--vividOrange-700: rgba(255, 93, 0, 0.7);
	--vividOrange-750: rgba(255, 93, 0, 0.75);
	--vividOrange-800: rgba(255, 93, 0, 0.8);
	--vividOrange-850: rgba(255, 93, 0, 0.85);
	--vividOrange-900: rgba(255, 93, 0, 0.9);
	--vividOrange-950: rgba(255, 93, 0, 0.95);
	--vividOrange-1000: rgba(255, 93, 0, 1);

	--gunmetal-50: rgba(31, 35, 48, 0.05);
	--gunmetal-100: rgba(31, 35, 48, 0.1);
	--gunmetal-150: rgba(31, 35, 48, 0.15);
	--gunmetal-200: rgba(31, 35, 48, 0.2);
	--gunmetal-250: rgba(31, 35, 48, 0.25);
	--gunmetal-300: rgba(31, 35, 48, 0.3);
	--gunmetal-350: rgba(31, 35, 48, 0.35);
	--gunmetal-400: rgba(31, 35, 48, 0.4);
	--gunmetal-450: rgba(31, 35, 48, 0.45);
	--gunmetal-500: rgba(31, 35, 48, 0.5);
	--gunmetal-550: rgba(31, 35, 48, 0.55);
	--gunmetal-600: rgba(31, 35, 48, 0.6);
	--gunmetal-650: rgba(31, 35, 48, 0.65);
	--gunmetal-700: rgba(31, 35, 48, 0.7);
	--gunmetal-750: rgba(31, 35, 48, 0.75);
	--gunmetal-800: rgba(31, 35, 48, 0.8);
	--gunmetal-850: rgba(31, 35, 48, 0.85);
	--gunmetal-900: rgba(31, 35, 48, 0.9);
	--gunmetal-950: rgba(31, 35, 48, 0.95);
	--gunmetal-1000: rgba(31, 35, 48, 1);

	--darkGunmetal-50: rgba(26, 29, 41, 0.05);
	--darkGunmetal-100: rgba(26, 29, 41, 0.1);
	--darkGunmetal-150: rgba(26, 29, 41, 0.15);
	--darkGunmetal-200: rgba(26, 29, 41, 0.2);
	--darkGunmetal-250: rgba(26, 29, 41, 0.25);
	--darkGunmetal-300: rgba(26, 29, 41, 0.3);
	--darkGunmetal-350: rgba(26, 29, 41, 0.35);
	--darkGunmetal-400: rgba(26, 29, 41, 0.4);
	--darkGunmetal-450: rgba(26, 29, 41, 0.45);
	--darkGunmetal-500: rgba(26, 29, 41, 0.5);
	--darkGunmetal-550: rgba(26, 29, 41, 0.55);
	--darkGunmetal-600: rgba(26, 29, 41, 0.6);
	--darkGunmetal-650: rgba(26, 29, 41, 0.65);
	--darkGunmetal-700: rgba(26, 29, 41, 0.7);
	--darkGunmetal-750: rgba(26, 29, 41, 0.75);
	--darkGunmetal-800: rgba(26, 29, 41, 0.8);
	--darkGunmetal-850: rgba(26, 29, 41, 0.85);
	--darkGunmetal-900: rgba(26, 29, 41, 0.9);
	--darkGunmetal-950: rgba(26, 29, 41, 0.95);
	--darkGunmetal-1000: rgba(26, 29, 41, 1);

	--black-50: rgba(0, 0, 0, 0.05);
	--black-100: rgba(0, 0, 0, 0.1);
	--black-150: rgba(0, 0, 0, 0.15);
	--black-200: rgba(0, 0, 0, 0.2);
	--black-250: rgba(0, 0, 0, 0.25);
	--black-300: rgba(0, 0, 0, 0.3);
	--black-350: rgba(0, 0, 0, 0.35);
	--black-400: rgba(0, 0, 0, 0.4);
	--black-450: rgba(0, 0, 0, 0.45);
	--black-500: rgba(0, 0, 0, 0.5);
	--black-550: rgba(0, 0, 0, 0.55);
	--black-600: rgba(0, 0, 0, 0.6);
	--black-650: rgba(0, 0, 0, 0.65);
	--black-700: rgba(0, 0, 0, 0.7);
	--black-750: rgba(0, 0, 0, 0.75);
	--black-800: rgba(0, 0, 0, 0.8);
	--black-850: rgba(0, 0, 0, 0.85);
	--black-900: rgba(0, 0, 0, 0.9);
	--black-950: rgba(0, 0, 0, 0.95);
	--black-1000: rgba(0, 0, 0, 1);

	--white-50: rgba(255, 255, 255, 0.05);
	--white-100: rgba(255, 255, 255, 0.1);
	--white-150: rgba(255, 255, 255, 0.15);
	--white-200: rgba(255, 255, 255, 0.2);
	--white-250: rgba(255, 255, 255, 0.25);
	--white-300: rgba(255, 255, 255, 0.3);
	--white-350: rgba(255, 255, 255, 0.35);
	--white-400: rgba(255, 255, 255, 0.4);
	--white-450: rgba(255, 255, 255, 0.45);
	--white-500: rgba(255, 255, 255, 0.5);
	--white-550: rgba(255, 255, 255, 0.55);
	--white-600: rgba(255, 255, 255, 0.6);
	--white-650: rgba(255, 255, 255, 0.65);
	--white-700: rgba(255, 255, 255, 0.7);
	--white-750: rgba(255, 255, 255, 0.75);
	--white-800: rgba(255, 255, 255, 0.8);
	--white-850: rgba(255, 255, 255, 0.85);
	--white-900: rgba(255, 255, 255, 0.9);
	--white-950: rgba(255, 255, 255, 0.95);
	--white-1000: rgba(255, 255, 255, 1);

	/* ******************************************************* */

	--headerHeight: 4.375rem;
	--sidebarWidth: 4.5rem;
	--sidebarWidthExpand: 15rem;
	--mobileBottomNavbarHeight: 3.75rem;
	--containerWidth: 78rem;

	@media screen and (max-width: 991.98px) {
		--headerHeight: 4.375rem;
		--sidebarWidth: 4.5rem;
		--sidebarWidthExpand: 15rem;
		--mobileBottomNavbarHeight: 3.75rem;
		--containerWidth: 78rem;
	}

	@media screen and (max-width: 474.98px) {
		--headerHeight: 4.375rem;
		--sidebarWidth: 4.5rem;
		--sidebarWidthExpand: 15rem;
		--mobileBottomNavbarHeight: 3.75rem;
		--containerWidth: 78rem;
	}
}
