footer {
	.content-container {
		margin: 0 auto;
	}
	// margin-left: 230px;
	background: var(--footerBg);
	@media (max-width: 767px) {
		padding-bottom: 50px;
	}
	.sidebar-close {
		margin-left: 66px;
	}
	& .custom-spacer {
		max-width: 1100px;
	}
	.footer-top {
		padding: 0;
		border-bottom: 1px solid var(--footerBorder);
		@media (max-width: 767px) {
			text-align: center;
			padding: 2rem 1rem;
		}
		p {
			font-size: 0.75rem;
			color: var(--authTabBtnText);
			font-weight: 600;
			line-height: 1.063rem;
		}
		.footer-contact-top {
			color: var(--lightkGray);
			h6 {
				font-weight: 300;
				font-size: 13px;
			}
		}
		.footer-linking {
			flex-direction: column;
			padding: 0 2rem;
			a {
				display: block;
				padding: 0.313rem 0;
				font-size: 0.875rem;
				font-weight: normal;
				color: var(--authTabBtnText);
			}
			h4 {
				color: var(--white);
				text-transform: capitalize;
				font-size: 1rem;
				font-weight: 600;
				padding-bottom: 0.2rem;
			}
		}
		.footer-logo {
			margin-bottom: 0.625rem;
			svg {
				width: 204px;
			}
		}
	}
	.footer-bottom {
		padding: 2rem 0rem;
		background: var(--footerBg);
		@media (max-width: 767px) {
			text-align: center;
			padding: 1rem;
		}
		img {
			margin-right: 2rem;
			@media (max-width: 767px) {
				margin-right: 0;
				margin-bottom: 1rem;
			}
		}
		p {
			font-size: 0.75rem;
			color: var(--authTabBtnText);
			font-weight: 500;
			line-height: 1.063rem;
			@media (max-width: 767px) {
				line-height: 1.5rem;
				margin: 0;
			}
			a {
				color: var(--white);
				padding: 0 0.2rem;
			}
		}
	}
	.language-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem 0rem;
		@media (max-width: 767px) {
			text-align: center;
			padding: 1rem;
			flex-direction: column;
		}
		.copyright-section {
			p {
				color: var(--white);
				font-size: 0.75rem;
				font-weight: normal;
				@media (max-width: 767px) {
					padding-bottom: 0.313rem;
				}
			}
		}
		.btn-secondary {
			width: auto;
			background: var(--selectBg);
			border-color: var(--footerBorder);
			color: var(--authTabBtnText);
			font-weight: 400;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.75rem;
			border-radius: 0.25rem;
			min-height: 32px;
			img {
				margin-right: 0.313rem;
			}
		}
	}
	.footer-bottom-linking {
		background: var(--footerLinking);
		padding: 1.2rem;

		p {
			font-size: 0.75rem;
			color: var(--authTabBtnText);
			font-weight: 600;
			position: relative;
			padding: 0 0.625rem;
			margin: 0;
			@media (max-width: 767px) {
				text-align: center;
			}
			&:before {
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				width: 1px;
				background: var(--white);
				content: '';
				@media (max-width: 767px) {
					display: none;
				}
			}
			&:last-child {
				&:before {
					display: none;
				}
			}
			a {
				color: var(--white);
				font-weight: 500;
				padding: 0 0.3rem;
			}
		}
	}
}
