.profile-wrap {
	margin-top: 0px;
	// max-width: 1040px;
	margin: 0 auto;

	@media (max-width: 767px) {
		padding: 1rem 0;
	}

	.profile-heading {
		h4 {
			font-size: 16px;
			color: var(--white);
			padding: 1rem 0;
			// text-transform: uppercase;
			font-weight: 600;
			display: flex;
			align-items: center;

			svg {
				margin-right: 0.625rem;
				width: 1.5rem;
			}
		}

		p {
			color: var(--white);
			font-size: 0.875rem;
			font-weight: 600;
		}
	}

	.trans-btn {
		background: var(--primaryBtn);
		border: 1px solid var(--theme-border);
		border-radius: 8px;
		color: var(--white);
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 1.125rem;
		// min-height: 48px;
		margin-top: 38px;
		margin-right: 10px;
		margin-left: 10px;
	}

	.reset-btn {
		background: var(--primaryBtn);
		border: 1px solid var(--theme-border);
		border-radius: 8px;
		color: var(--white);
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 1.125rem;
		// min-height: 48px;
		// margin-top: 38px;
		// margin-right: 10px;
		// margin-left: 10px;
		width: 100%;
		vertical-align: -32px;
		@media (max-width: 599.98px) {
			vertical-align: -0;
		}
	}

	.selectProvider__menu {
		background-color: #121c27;
		margin: 0;
		& .selectProvider__option {
			font-weight: 600;
			font-size: 14px;
			&:hover {
				background-color: var(--secondaryBtn);
				border-radius: 4px;
				color: #000;
				cursor: pointer;
			}
		}
	}
	.selectProvider__control {
		min-height: 42px;
		& .selectProvider__single-value {
			font-weight: 500;
			font-size: 12px;
			// &.selectProvider__multi-value__label  {
			// 	color:#fff
			// }
		}
		// .selectProvider__value-container {
		// 	// padding-left: 30px;
		// 	& .selectProvider__multi-value {
		// 		&.selectProvider__multi-value__label  {
		// 			color:#fff
		// 		}
		// 	}

		// }
	}
	.profile-card-wrap {
		width: 100%;
		background: var(--profileCard);
		padding: 1.5rem;
		border-radius: 8px;

		.deposit-note {
			// color: #fff;
			color: #b1b6c6;
			margin: 0.5rem 0 0.8rem 0;
			display: block;
			font-size: 0.8rem;
			font-weight: 500;
		}
		.min-amount-tag {
			font-size: 14px;
			font-weight: 600;
		}
		.total-withdraw-amount {
			color: #fff;
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			font-weight: 600;
			margin: 1rem auto;
		}

		.clutch-profile-right {
			padding: 0 1rem;

			h4 {
				font-size: 1.25rem;
				font-weight: 600;
				color: var(--white);

				@media (max-width: 767px) {
					font-size: 0.875rem;
				}
			}
		}

		.account-progress-wrap {
			.progress {
				height: 5px;
				background: var(--darkGray);

				.progress-bar {
					width: 50%;
					border-radius: 8px;
					background: var(--statusColorOne);
				}
			}

			.progrees-details {
				margin: 0.625rem 0;

				h4 {
					font-size: 0.875rem;
					color: var(--authSocailTextColor);
					font-weight: 500;

					span {
						text-transform: uppercase;
						color: var(--statusColorOne);
					}
				}
			}
		}

		.clutch-profile-left {
			position: relative;

			&:before {
				position: absolute;
				right: 6px;
				width: 1px;
				height: 70%;
				background: var(--profileCardBorder);
				content: '';
				top: 20px;

				@media (max-width: 767px) {
					right: 0px;
				}
			}

			& svg {
				@media (max-width: 767px) {
					width: 60px;
				}
			}
		}
		.amount-error-msg {
			color: red;
			display: block;
			margin-top: 6px;
			margin-left: 0.4rem;
			font-size: 0.9rem;
		}
		.submit-button-fund {
			.show-loader {
				margin: auto;
				width: 2rem;
				height: 2rem;
				color: #fff;
			}
		}
	}
	.documents-verified-wrapper {
		min-height: 24rem;
		color: #fff;
		text-align: center;
		font-weight: 500;
		font-size: 1.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.page-link {
		padding: 0.375rem 0.75rem;
		background: var(--profileCard);
		color: white;
		border-radius: 8px;
		border: 1px solid var(--profileCardBorder);
	}

	.pagination {
		display: flex;
		padding-left: 0;
		list-style: none;
		justify-content: flex-end;
	}

	.profile-total-card-wrap {
		background: var(--profileCard);
		padding: 0.77rem 0.625rem;
		border-radius: 8px;
		border: 1px solid var(--profileCardBorder);
		display: flex;
		align-items: flex-start;
		justify-content: space-around;

		@media (max-width: 767px) {
			// flex-direction: column;
			align-items: center;
			text-align: center;
			flex-wrap: wrap;
		}

		h4 {
			color: var(--white);
			font-weight: 600;
			font-size: 0.875rem;
			line-height: 0.75rem;

			@media (max-width: 767px) {
				justify-content: center;
			}
		}

		p {
			font-size: 0.75rem;
			line-height: 0.75rem;
			color: var(--authSocailTextColor);
			padding-bottom: 1rem;

			@media (max-width: 767px) {
				padding-bottom: 0.625rem;
			}
		}

		.profile-total-card {
			position: relative;

			&:before {
				position: absolute;
				right: -0.75rem;
				width: 1px;
				height: 100%;
				background: var(--profileCardBorder);
				content: '';
				top: 0px;

				@media (max-width: 991px) {
					display: none;
				}
			}

			&:last-child {
				&:before {
					display: none;
				}
			}

			@media (max-width: 767px) {
				margin-bottom: 1rem;
				width: 50%;
			}
		}
	}

	.input {
		margin: 10px;
	}

	.custom-switch-wrap {
		label {
			color: var(--authSocailTextColor);
			font-weight: 500;
			font-size: 0.875rem;
			line-height: 1.75rem;
		}

		.custom-switch {
			position: relative;
			background: var(--theme-dark);
			width: 40px;
			height: 22px;
			border-radius: 100vh;
			transition: background 0.4s ease;

			&::before {
				top: 4px;
				bottom: auto;
				content: '';
				position: absolute;
				left: 4px;
				width: 14px;
				height: 14px;
				border-radius: 100vh;
				background: var(--profileCardBorder);
				transition: transform 0.4s ease;
			}

			&:has(input[type='checkbox']:checked) {
				background: var(--liveGreen);

				&::before {
					transform: translateX(19px);
				}
			}

			input[type='checkbox'] {
				opacity: 0;
				position: absolute;
				inset: 0;
				cursor: pointer;
			}
		}
	}

	.react-datepicker__input-container {
		input {
			background-color: var(--inputbg);
			border: 1px solid var(--inputBorder);
			color: var(--white);
			padding: 7px 12px;
			border-radius: 8px;
			font-weight: 500;
			// height: 38px;
			height: 42px;
			font-size: 12px;

			&:focus {
				color: var(--white);
			}
		}
	}

	.change-username {
		label {
			text-transform: uppercase;
			color: var(--authSocailTextColor);
			font-size: 0.875rem;

			span {
				color: var(--loss);
			}
		}

		.form-group {
			position: relative;

			.btn-secondary,
			.btn-secondary-outline {
				position: absolute;
				right: 0.313rem;
				top: 2rem;
				min-height: 2.25rem;
				font-size: 0.875rem;
				min-width: 144px;
			}

			.form-control {
				background-color: var(--theme-dark) !important;

				&:focus {
					border-color: var(--secondaryBtnBorder);
					// box-shadow: var(--secondaryBtnBorder) 0px 0px 1px inset, var(--sidebarHover) 0px 0px 2px  !important;
				}
			}
		}

		p {
			color: var(--white);
			text-transform: uppercase;
			font-size: 1rem;
			line-height: 1.75rem;
			font-weight: 700;
		}

		.form-text-wrap {
			margin: 0.625rem 0;

			p {
				font-size: 0.75rem;
				font-weight: 500;
				line-height: 1.063;
				color: var(--authSocailTextColor);
				text-transform: capitalize;
			}
		}
	}

	.profile-inner-heading {
		color: var(--white);
		font-weight: 700;
		font-size: 1rem;
		line-height: 1.75rem;
		text-transform: uppercase;
	}

	.link-socail-section {
		.link-socail-listing {
			flex-wrap: wrap;

			.social-link-card {
				background: var(--theme-dark);
				border-radius: 8px;
				padding: 0.313rem;
				flex: 0 0 auto;
				width: calc(33% - 5px);
				margin: 0.313rem 0;

				.social-link-icon {
					background: var(--profileSocialBg);
					border: 1px solid var(--profileCardBorder);
					border-radius: 8px;
					width: 38px;
					height: 38px;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					&:before {
						position: absolute;
						left: 0;
						top: 0;
						background: var(--liveGreen);
						height: 0.563rem;
						width: 0.563rem;
						border-radius: 100%;
						content: '';
					}
				}

				.social-text {
					color: var(--authSocailTextColor);
					font-size: 0.875rem;
					font-weight: 500;
				}

				@media (max-width: 767px) {
					width: 100%;
				}
			}
		}
	}

	.btn-secondary-outline {
		background: var(--primaryBtn);
		border: 1px solid var(--theme-border);
		border-radius: 8px;
		color: var(--white);
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 1.125rem;
		// min-height: 56px;

		&:hover {
			background: var(--secondaryBtn);
			color: var(--button-text);
		}
	}

	.user-account-section {
		.user-account-header {
			h4 {
				color: var(--authTabBtnText);
				font-weight: 500;
				font-size: 1rem;
				line-height: 1.25rem;

				@media (max-width: 991px) {
					font-size: 0.75rem;
				}

				span {
					color: var(--liveGreen);
				}
			}

			a {
				color: var(--authTabBtnText);
				font-weight: 500;
				font-size: 1rem;
				line-height: 1.25rem;

				@media (max-width: 991px) {
					font-size: 0.75rem;
				}
			}
		}

		.user-account-card {
			background: var(--theme-dark);
			border: 1px solid var(--profileCardBorder);
			padding: 1.45rem 1rem;
			border-radius: 8px;
			color: var(--white);
			margin-bottom: 1rem;

			.user-account-card-left {
				h4 {
					font-size: 1.125rem;
					line-height: 1.25rem;
					font-weight: 500;

					@media (max-width: 991px) {
						font-size: 0.75rem;
					}
				}

				.user-account-icon {
					height: 1.125rem;
					width: 1.125rem;
					background: var(--secondaryBtnBorder);
					border-radius: 100%;
					border: 6px solid var(--profileOutlineBtn);
					margin-right: 0.625rem;
				}
			}

			.user-account-right {
				h4 {
					color: var(--white);
					font-size: 1rem;
					font-weight: 500;

					@media (max-width: 991px) {
						font-size: 0.75rem;
					}
				}
			}
		}
	}

	.tips-section {
		.user-account-header {
			h4 {
				color: var(--authTabBtnText);
				font-weight: 500;
				font-size: 1rem;
				line-height: 1.25rem;

				@media (max-width: 991px) {
					font-size: 0.75rem;
				}

				span {
					color: var(--liveGreen);
				}
			}
		}
	}

	.verification-labels {
		overflow-x: auto;
		white-space: nowrap;
		flex-direction: row;
		flex-wrap: nowrap;
		width: 800px;
	}
	.horizontal-theme-tabs {
		.nav-link {
			justify-content: center;
			background: var(--primaryBtn);
			border: 1px solid var(--theme-border);
			margin: 0;
			line-height: 11px;
			padding: 1rem;
			color: var(--white);
			opacity: 1;

			&.active,
			&:hover {
				background: var(--secondaryBtn);
				color: var(--button-text);
				border: transparent;
				// border-color: var(--secondry-border);
				opacity: 1;
				box-shadow:
					rgba(255, 176, 25, 0.4) 0px 0px 10px,
					rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
					rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
					rgb(255, 135, 25) 0px 0px 15px inset;
			}
		}

		@media (max-width: 767px) {
			overflow-x: auto;
			white-space: nowrap;
			flex-direction: row;
			flex-wrap: nowrap;
			width: 100%;
		}
	}

	.theme-table {
		font-weight: 500;
		font-size: 0.875rem;

		a {
			color: var(--white);
		}
	}

	.refferal-tab-wrap {
		label {
			color: var(--white);
			font-weight: 600;
			font-size: 0.875rem;
		}
	}

	.verification-box-head {
		display: flex;
		align-items: center;
		margin-top: 10px;
		justify-content: center;
		font-size: 20px;
		margin-bottom: 25px;
		color: #fff;
	}

	.reject-icon {
		color: #ff0000;
		fill: #ff0000;
		margin-right: 10px;
		margin-top: -4px;
	}

	.pending-icon {
		color: #ffffff;
		fill: #ffffff;
		margin-right: 10px;
		margin-top: -4px;
	}

	.approve-icon {
		color: #40ff00;
		fill: #40ff00;
		margin-right: 10px;
		margin-top: -4px;
	}

	.verification-inte-box {
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: '767px') {
			flex-wrap: wrap;
			gap: 1rem;
		}

		.verification-uploadleft-box {
			width: 50%;
			margin-left: 15px;
			@media (max-width: '599.98px') {
				width: 100%;
				margin-bottom: 1rem;
			}
		}
	}

	.verification-btn-bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
	}

	.doc-file-name {
		svg {
			fill: #ff0000;
		}
	}
}

.selected-page {
	background-color: var(--secondaryRadialBtn) !important;
	color: #000;
}

.pagination-btn {
	border-radius: 8px;
	border: 1px solid var(--profileCardBorder);
	background-color: var(--profileCardBorder);
	padding: 0.375rem 0.95rem;
	margin: 0 0.3rem;
	font-weight: 600;
	font-size: 14px;
}

.disabled-btn {
	background-color: var(--providerCard) !important;
}

.pagination-container {
	display: flex;
	justify-content: flex-end;
}

.checkBox {
	.MuiSvgIcon-root {
		color: var(--secondaryBtn);
	}
}

.mui-icon-button {
	position: relative !important;
	left: unset !important;
	right: unset !important;
	top: unset !important;
}
.my_btn_css {
	min-height: 40px !important;
	margin-top: 20px !important;
}
.referral_copy_btn {
	position: absolute;
	right: 2px;
	top: 11px;
	svg {
		position: static !important;
	}
}
.referral_input {
	padding-left: 10px !important;
	padding-right: 60px;
}

.amt-btn-box {
	display: flex;
	gap: 0.25rem;
	margin-top: 0.25rem;

	.amt-btn {
		width: 100%;
		background-color: var(--black-400);
		border: 0;
		border-radius: 0.5rem;
		color: var(--white-1000);
		font-size: 0.875rem;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.75rem;
		z-index: 0;
		padding: 0.5rem 0.75rem;
		margin: 0;
		position: relative;
		transition: 200ms all ease-in-out;

		&:hover,
		&:active {
			background-color: var(--black-300);
		}
	}
}
.amt-text-box {
	border-bottom: 1px solid var(--inputBorder);
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	padding: 1rem 0;
	margin-bottom: 0.5rem;

	.text {
		color: var(--white-1000);
		font-size: 1rem;
		font-weight: 400;
		margin: 0;

		a {
			text-decoration: underline;

			&:hover {
				color: var(--primaryMain-1000);
			}
		}
	}
}
.withdraw-list-text-box {
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	margin-bottom: 0.5rem;

	.text-1 {
		color: var(--white-1000);
		font-size: 1rem;
		font-weight: 400;
		margin: 0;

		&.text-yellow {
			color: var(--primaryMain-1000);
		}
	}

	.text-2 {
		color: var(--white-1000);
		font-size: 1rem;
		font-weight: 800;
		margin: 0;

		&.text-yellow {
			color: var(--primaryMain-1000);
		}
	}
}
.currency-options-btn-box {
	display: flex;
	flex-wrap: wrap;
	gap: 0.25rem;
	margin-bottom: 1rem;

	.currency-options-btn {
		width: fit-content;
		background-color: var(--black-400);
		border: 1px solid transparent;
		border: 0;
		border-radius: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		z-index: 0;
		padding: 0.5rem 1rem;
		margin: 0;
		position: relative;
		transition: 200ms all ease-in-out;

		.icon-box {
			width: 1.25rem;
			min-width: 1.25rem;
			height: 1.25rem;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}

		.text {
			color: var(--white-1000);
			font-size: 0.875rem;
			font-weight: 600;
		}

		&:hover,
		&:active {
			background-color: var(--black-300);
		}

		&.active {
			background-color: var(--black-300);
			border: 1px solid var(--primaryMain-1000);
		}
	}
	.currency-btn-selected {
		background: #dbcc41;
		.text {
			color: #000;
		}
		&:hover {
			background: #dbcc41;
		}
	}
}
