.MuiTabs-indicator {
	display: none;
}

.submit-button {
	width: 25%;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin: 1rem 0 2rem 0;
	span {
		.show-loader {
			width: 2.2rem;
			height: 2.2rem;
			margin: auto;
			color: #fff;
		}
	}
}

.form-control[readonly] {
	background-color: transparent;
}

.address-wrapper {
	width: 100%;
	margin-top: 1.5rem;
	.qr-code-wrapper {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
	}
	.deposit-address {
		margin-bottom: 1.4rem;
	}
	.deposit-url {
		margin-bottom: 1rem;
		cursor: pointer;
	}
	.copy-button {
		width: 100%;
	}
}
