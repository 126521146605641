// body {
//   @media(max-width:550px) {
//     min-width: 470px;
//   }
// }

.mr-2 {
	margin-right: 2rem;
}
div.react-toast-notifications__container {
	z-index: 1250;
}
.scroll-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
}

// .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
//     border-top-right-radius: 60px;
//     border-bottom-right-radius: 60px;
// }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
