.play-now-text {
	position: absolute;
	top: 57%;
	color: white;
	left: 28%;
}

.play-now-button {
	width: 30px !important;
	height: 30px !important;
	min-width: unset !important;
	padding: 0 !important;
	position: absolute !important;
	top: 25%;
	left: 40%;
}

.fav-icon {
	width: 20px;
	height: 20px;
	min-width: unset;
	padding: 0;
	position: absolute;
	top: 3%;
	left: 5px;
}
