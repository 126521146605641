.main-wrap {
	background: url('../../../public/assets/images/homePage/body-bg-img.png');
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: right 30vh;
}

.sticky-top {
	z-index: 50;
}

.banner-wrap {
	width: 100%;
}

.banner-wrap .img-box,
.banner-wrap .text-box {
	margin: 60px 0;
}

.banner-wrap .img-box {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.banner-wrap .text-box {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}

.banner-wrap .text-box .text-1 {
	color: var(--white);
	font-size: 44px;
	font-weight: 600;
	display: block;
}

.banner-wrap .text-box .text-2 {
	background: var(--primaryBtn);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 90px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 80px;
	display: block;
}

.banner-wrap .text-box .text-3 {
	color: var(--white);
	font-size: 44px;
	font-weight: 600;
	display: block;
}

@media screen and (max-width: 991.98px) {
	.banner-wrap .img-box {
		margin: 0;
	}

	.banner-wrap .text-box {
		margin: 50px 0;
	}
}

@media screen and (max-width: 767.98px) {
	.banner-wrap .img-box {
		margin: 0;
	}

	.banner-wrap .text-box {
		margin: 40px 0;
	}

	.banner-wrap .text-box .text-2 {
		font-size: 50px;
		line-height: 50px;
	}

	.banner-wrap .text-box .text-1,
	.banner-wrap .text-box .text-3 {
		font-size: 30px;
	}
}

@media screen and (max-width: 575.98px) {
	.banner-wrap .img-box {
		margin: 0;
	}

	.banner-wrap .text-box {
		margin: 20px 0;
	}

	.banner-wrap .text-box .text-2 {
		font-size: 40px;
		line-height: 40px;
	}

	.banner-wrap .text-box .text-1,
	.banner-wrap .text-box .text-3 {
		font-size: 24px;
	}
}

.game-type-wrap {
	padding: 80px 0 80px;
}

@media screen and (max-width: 767.98px) {
	.game-type-wrap {
		padding: 50px 0 50px;
	}
}

@media screen and (max-width: 575.98px) {
	.game-type-wrap {
		padding: 20px 0 20px;
	}
}

.game-type-wrap .card {
	width: 80%;
	background-color: var(--darkSlate);
	border: 0;
	box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	padding: 40px;
	margin: 20px 0;
}

@media screen and (max-width: 991.98px) {
	.game-type-wrap .card {
		width: 100%;
	}
}

.game-type-wrap .card .img-box {
	display: flex;
	justify-content: center;
	align-items: center;
}

.game-type-wrap .card .img-box img {
	width: 90%;
}

.game-type-wrap .card .card-body {
	padding: 0;
}

.game-type-wrap .card .card-body .game-name {
	color: var(--white);
	font-size: 30px;
	font-weight: 600;
	text-align: center;
	margin: 20px 0 0;
}

.game-slider-wrap {
	background: var(--primaryBtn);
	padding: 100px 0 100px 100px;
}

@media screen and (max-width: 767.98px) {
	.game-slider-wrap {
		padding: 50px 0 50px 50px;
	}
}

@media screen and (max-width: 575.98px) {
	.game-slider-wrap {
		padding: 50px 0 50px 20px;
	}
}

.game-slider-wrap .title-text {
	color: var(--white);
	font-size: 44px;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 60px;
}

@media screen and (max-width: 767.98px) {
	.game-slider-wrap .title-text {
		font-size: 30px;
		margin-bottom: 40px;
	}
}

@media screen and (max-width: 575.98px) {
	.game-slider-wrap .title-text {
		font-size: 20px;
		margin-bottom: 20px;
	}
}

.game-slider-wrap .game-box {
	border-radius: 12px;
	overflow: hidden;
	padding: 0 20px;
	position: relative;
}

.game-slider-wrap .game-box .img-box {
	width: 100%;
	height: 100%;
	border-radius: 12px;
	overflow: hidden;
	position: relative;
	cursor: pointer;
}

.game-slider-wrap .game-box .img-box::before {
	content: '';
	display: block;
	padding-top: 100%;
}

.game-slider-wrap .game-box .img-box img {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	object-fit: cover;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.game-slider-wrap .game-box .favorite-btn {
	position: absolute;
	top: 20px;
	right: 40px;
}

.bonus-wrap {
	padding: 40px 0 100px;
}

@media screen and (max-width: 767.98px) {
	.bonus-wrap {
		padding: 40px 0 50px;
	}
}

@media screen and (max-width: 575.98px) {
	.bonus-wrap {
		padding: 20px 0 20px;
	}
}

.bonus-wrap .title-text {
	color: var(--white);
	font-size: 44px;
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 60px;
}

@media screen and (max-width: 767.98px) {
	.bonus-wrap .title-text {
		font-size: 30px;
		margin-bottom: 40px;
	}
}

@media screen and (max-width: 575.98px) {
	.bonus-wrap .title-text {
		font-size: 20px;
		margin-bottom: 20px;
	}
}

.bonus-wrap .bonus-img-wrap {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.bonus-wrap .bonus-img-wrap .img-box {
	width: 20%;
	padding: 10px;
}

@media screen and (max-width: 991.98px) {
	.bonus-wrap .bonus-img-wrap .img-box {
		width: 33.333%;
	}
}

@media screen and (max-width: 767.98px) {
	.bonus-wrap .bonus-img-wrap .img-box {
		width: 50%;
	}
}

@media screen and (max-width: 575.98px) {
	.bonus-wrap .bonus-img-wrap .img-box {
		width: 50%;
	}
}

.bonus-wrap .bonus-img-wrap .img-box img {
	filter: grayscale(1);
	-webkit-filter: grayscale(1);
}

.bonus-wrap .bonus-img-wrap .img-box img:hover {
	filter: grayscale(0);
	-webkit-filter: grayscale(0);
	transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: var(--inputText) !important;
	font-size: 14px;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: var(--inputText) !important;
	font-size: 14px;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: var(--inputText) !important;
	font-size: 14px;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: var(--inputText) !important;
	font-size: 14px;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
	padding: 0;
}
.load-more-btn .btn {
	padding: 14px 20px;
	min-width: 255px;
	line-height: 32px;
	color: var(--white);
}

.btn-secondary:hover,
.btn-secondary:active {
	background: var(--primaryBtn);
	color: var(--white);
	box-shadow: none;
}

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: none;
}

.noSideNav header {
	padding: 20px 15px;
}

.accordion-item,
.accordion-button,
.accordion-button:not(.collapsed) {
	background: transparent;
	border: none;
	color: var(--gray);
	font-weight: 600;
	box-shadow: none;
}

.accordion-button {
	padding: 15px 10px;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
	background: url('../../../public/assets/images/homePage/accordian-arrow.svg');
	background-size: 14px;
	height: 14px;
	width: 14px;
	background-repeat: no-repeat;
}

.accordion-button:focus {
	box-shadow: none;
	border: none;
}

.sports-league-listing a {
	color: var(--gray);
	font-weight: 400;
	font-size: 12px;
	padding: 10px;
	border-radius: 30px;
	margin-bottom: 5px;
}

.sports-league-listing a.active,
.sports-league-listing a:hover {
	background: var(--lightSlate);
}

.language-select-wrap {
	position: relative;
}

.language-select-wrap select {
	background-color: var(--lightSlate);
	border-color: var(--lightSlate);
	color: var(--white);
	border-radius: 30px;
	background-image: url('../../../public/assets/images/homePage/down-arrow.svg');
	padding-left: 50px;
	height: 40px;
}

.language-select-wrap img {
	position: absolute;
	top: 4px;
	left: 5px;
}

.theme-switcher-wrap {
	padding: 20px 10px 25px;
	border-bottom: 1px solid var(--darkAqua);
}

.theme-switcher-wrap input[type='checkbox'],
.theme-switcher-wrap input[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}

.theme-switcher-wrap .toggle-checkbox {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.theme-switcher-wrap .toggle-checkbox:checked ~ .toggle-slot {
	background-color: var(--lightSlate);
	position: relative;
}

.theme-switcher-wrap .toggle-slot {
	height: 40px;
	width: 80px;
	border: 5px solid var(--lightSlate);
	background: var(--lightSlate);
	border-radius: 10em;
	transition: background-color 0.25s;
	cursor: pointer;
	position: relative;
}

.theme-switcher-wrap .toggle-checkbox:checked ~ .toggle-slot .toggle-button {
	box-shadow: inset 0 0 0 0.75em var(--aquaBlue);
	-webkit-transform: translate(2.5em);
	transform: translate(2.5em);
	position: relative;
}

.theme-switcher-wrap
	.toggle-checkbox:checked
	~ .toggle-slot
	.toggle-button:after {
	position: absolute;
	content: '';
	background: url('../../../public/assets/images/homePage/sun.svg');
	background-size: 20px;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	top: 5px;
	left: 4px;
	filter: contrast(20);
}

.theme-switcher-wrap .toggle-slot:before {
	position: absolute;
	content: '';
	background: url('../../../public/assets/images/homePage/moon.svg');
	background-size: 16px;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	top: 6px;
	left: 6px;
	z-index: 1;
}

.theme-switcher-wrap .toggle-button {
	position: absolute;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	background-color: var(--aquaBlue);
	transition:
		background-color 0.25s,
		border-color 0.25s,
		-webkit-transform 0.5s cubic-bezier(0.26, 2, 0.46, 0.71);
	transition:
		background-color 0.25s,
		border-color 0.25s,
		transform 0.5s cubic-bezier(0.26, 2, 0.46, 0.71);
	transition:
		background-color 0.25s,
		border-color 0.25s,
		transform 0.5s cubic-bezier(0.26, 2, 0.46, 0.71),
		-webkit-transform 0.5s cubic-bezier(0.26, 2, 0.46, 0.71);
	left: 0px;
	top: 0px;
}

.theme-switcher-wrap .toggle-checkbox:checked ~ .toggle-slot:after {
	position: absolute;
	content: '';
	background: url('../../../public/assets/images/homePage/sun.svg');
	background-size: 20px;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	top: 5px;
	left: auto;
	right: 6px;
	-webkit-transform: rotate(215deg);
	transform: rotate(215deg);
	filter: contrast(20);
}

.theme-switcher-wrap .toggle-slot:after {
	position: absolute;
	content: '';
	background: url('../../../public/assets/images/homePage/sun.svg');
	background-size: 20px;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	top: 5px;
	left: auto;
	right: 6px;
	-webkit-transform: rotate(215deg);
	transform: rotate(215deg);
}

.theme-label p {
	font-size: 14px;
	color: var(--white);
	line-height: 21px;
	font-weight: 600;
}

.casino-card:before {
	content: '';
	display: block;
	padding-top: 100%;
}

.casino-card > img {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100%;
}

.overlaywrap {
	position: absolute;
	width: 100%;
	height: 100%;
	background: var(--gradientHover);
	top: 0;
	display: none;
}

.casino-card:hover .overlaywrap {
	display: block;
}

.casino-card .like-icon {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 1;
}

.casino-card .like-icon img {
	width: 19px;
}

.home-filter-wrap {
	margin-bottom: 45px;
}

.home-filter-left {
	border-radius: 34px;
	display: flex;
	align-items: center;
}
.nav-tabs .nav-link {
	border: 0;
	cursor: pointer;
}

/* COMMON ODDS DESIGN START FROM HERE */
.odds-wrap .match-details-card {
	background: var(--primaryDark);
	border-radius: 9px;
	margin: 20px 0 0;
}

.odds-wrap .match-details-card li {
	width: 100%;
	text-align: center;
	display: flex;
	align-items: center;
	flex-flow: column;
	padding: 5px 0;
	border-right: 1px solid var(--dark);
}

.odds-wrap .match-details-card li:last-child {
	border: none;
}

.odds-wrap .match-details-card li h5 {
	color: var(--aquaBlue);
	font-size: 10px;
	margin: 0;
}

.odds-wrap .match-details-card li span {
	font-size: 10px;
	color: var(--gray);
	font-weight: 400;
}

/* COMMON ODDS DESIGN START FROM HERE */
/* BETSLIP START FROM HERE */
.betslip-wrap {
	background: var(--darkSlate);
	min-width: 340px;
	position: fixed;
	right: 0px;
	z-index: 2;
	bottom: 0;
}

.betslip-listing {
	padding: 20px;
}

.betslip-header {
	background: var(--aquaBlue);
	padding: 10px;
	border-radius: 18px 18px 0px 0px;
}

.betslip-tab-wrap {
	background: var(--lightSlate);
	border-radius: 30px;
	display: flex;
	justify-content: center;
}

.betslip-tab-wrap li {
	width: 50%;
}

.betslip-tab-wrap li button {
	width: 100%;
}

.betslip-content-wrap {
	padding: 20px;
	border-bottom: 1px solid var(--casal);
}

.betcard-close-btn img {
	width: 18px;
}

.betslip-card {
	background: var(--lightSlate);
	border-radius: 4px;
	margin-bottom: 15px;
}

.betcard-header {
	background: var(--aquaBlue);
	padding: 10px;
	border-radius: 4px 4px 0px 0px;
}

.betcard-content {
	padding: 20px;
}

.betcard-header h5 {
	font-size: 12px;
	font-weight: 500;
	color: var(--lightSlate);
}

.bet-input .form-select {
	background-color: var(--darkSlate);
	border-radius: 0;
	color: #fff;
	background-image: url('../../../public/assets/images/homePage/form-select.svg');
	background-repeat: no-repeat;
	background-size: 10px;
	background-position: 62% 50%;
	border-color: transparent;
}

.bet-input {
	position: relative;
	max-width: 130px;
}

.form-select:focus {
	box-shadow: none;
}

.bet-text h4 {
	color: var(--white);
	font-size: 14px;
	font-weight: 600;
}

.bet-text {
	margin-bottom: 15px;
}

.bet-text .betcard-odd {
	color: var(--aquaBlue);
	font-size: 14px;
	font-weight: 600;
}

.bet-currency {
	position: absolute;
	right: 8px;
	top: 7px;
	color: var(--white);
}

.bet-currency:before {
	position: absolute;
	right: 20px;
	content: '';
	height: 20px;
	width: 1px;
	top: 3px;
	background: var(--white);
}

/* BETSLIP END HERE */

.head-title-text {
	background: var(--gradientText);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 40px;
	font-weight: 600;
	text-align: center;
}

.head-sub-text {
	color: var(--white);
	font-size: 18px;
	font-weight: 400;
	text-align: center;
	margin-top: 16px;
}

.game-box .form-control:disabled {
	background-color: var(--darkBlue);
	opacity: 0.5;
}

.input-group .form-control[type='password'],
.input-group .form-control.form-icon {
	border-radius: 60px !important;
}

.input-group.btnBefore .form-control {
	padding-left: 44px;
}

.input-group.btnAfter .form-control {
	padding-right: 44px;
}

.input-group .showPassBtn,
.input-group .addonBtn {
	background-color: transparent;
	border: 0;
	color: var(--inputText);
	font-size: 13px;
	text-decoration: underline;
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	z-index: 3;
}

.form-btn {
	background: transparent;
	border: 0;
	color: var(--white);
	text-transform: uppercase;
	padding: 3px 6px;
}

.form-btn:hover {
	color: var(--aquaBlue);
}

.modal .login-modal {
	// max-width: 393px;
	margin: 0 auto;
	animation: modalEffect 0.8s ease-in-out 0s 1 normal none running;
}

.btn-close:hover {
	box-shadow: none;
}

.modal .signup-modal {
	width: 100%;
}

.modal.login-signup-modal .modal-content .modal-header,
.modal.login-signup-modal .modal-content .modal-body,
.modal.login-signup-modal .modal-content .modal-footer {
	border: 0;
	padding: 18px;
}

.modal.login-signup-modal .modal-content .btn-close {
	width: unset;
	height: unset;
	background-image: none;
	padding: 0;
	margin: 0;
}

.modal.login-signup-modal .modal-content .btn-close img {
	width: 20px;
	height: 20px;
}

.modal.login-signup-modal .modal-content .form-label {
	color: var(--lightkGray);
}

.modal.login-signup-modal .modal-content .form-control-box {
	background: transparent;
	border-radius: 26px;
}

.modal.login-signup-modal
	.modal-content
	.form-control-box:has(.form-control:focus) {
	background: var(--modalInputFocus);
}

.modal.login-signup-modal .modal-content .form-control {
	background-color: var(--modalInputBG);
	border: 1px solid transparent;
	border-radius: 26px;
	color: var(--white);
}

.modal.login-signup-modal .modal-content .btn {
	width: 100%;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	padding: 15px 40px;
}

.modal.login-signup-modal .modal-content .modal-btn {
	background: transparent;
	border: 0;
	color: var(--aquaBlue);
	padding: 3px 6px;
}

.modal.login-signup-modal .modal-content .modal-text {
	color: var(--lightkGray);
}

.modal.login-signup-modal .modal-content .link-text {
	color: var(--aquaBlue);
}

/* Dots */
.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: -25px;

	display: block;

	width: 100%;
	padding: 0;
	margin: 0;

	list-style: none;

	text-align: center;
}

.slick-dots li {
	position: relative;

	display: inline-block;

	width: 20px;
	height: 20px;
	margin: 0 5px;
	padding: 0;

	cursor: pointer;
}

.slick-dots li button {
	font-size: 0;
	line-height: 0;

	display: block;

	width: 20px;
	height: 20px;
	padding: 5px;

	cursor: pointer;

	color: transparent;
	border: 0;
	outline: none;
	background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
	outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
	opacity: 1;
}

.slick-dots li button:before {
	font-family: 'slick';
	font-size: 6px;
	line-height: 20px;

	position: absolute;
	top: 0;
	left: 0;

	width: 20px;
	height: 20px;

	content: '•';
	text-align: center;

	opacity: 0.25;
	color: black;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
	opacity: 0.75;
	color: black;
}

/* Slider */
.slick-slider {
	position: relative;

	display: block;
	box-sizing: border-box;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;

	display: block;
	overflow: hidden;

	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;

	display: flex;
}

.slick-track:before,
.slick-track:after {
	display: table;

	content: '';
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;

	height: 100%;
	min-height: 1px;
}

[dir='rtl'] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;

	height: auto;

	border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
	display: none;
}

footer a:hover {
	color: var(--aquaBlue);
}

.social-listing p {
	color: var(--lightkGray);
}

.social-listing a {
	padding: 10px;
	display: inline-block;
}

.footer-contact {
	padding-left: 30px;
}

@media screen and (max-width: 991.98px) {
	.footer-contact {
		padding-left: 0;
	}
}

.modal .modal-content .modal-body .content-box {
	width: calc(100% - 3rem);
	height: calc(100% - 7rem);
	background-color: var(--modalBG);
	margin-top: 1rem;
	margin-left: 1.5rem;
	text-align: center;
	color: white;
}

.show-loader {
	z-index: 1;
	//   position: absolute;
	//   left: 48%;
	//   top: 40%;
	width: 3rem;
	height: 3rem;
	color: var(--aquaBlue);
}

.login-input {
	color: var(--login-input-label);
}

.form-group.required .control-label:after {
	content: '*';
	color: red;
}

.email-verification-container {
	min-height: 57vh;
}

@keyframes modalEffect {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 0;
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
