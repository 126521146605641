// @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&family=Teko:wght@600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
	font-family: 'Averta';
	src:
		url('./assets/fonts/AvertaStd-Black.ttf') format('ttf'),
		url('./assets/fonts/AvertaStd-Black.ttf') format('ttf');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Averta';
	src:
		url('./assets/fonts/AvertaStd-Bold.ttf') format('ttf'),
		url('./assets/fonts/AvertaStd-Bold.ttf') format('ttf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Averta';
	src:
		url('./assets/fonts/AvertaStd-ExtraBold.ttf') format('ttf'),
		url('./assets/fonts/AvertaStd-ExtraBold.ttf') format('ttf');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Averta';
	src:
		url('./assets/fonts/AvertaStd-Extrathin.ttf') format('ttf'),
		url('./assets/fonts/AvertaStd-Extrathin.ttf') format('ttf');
	font-weight: 100;
	font-style: normal;
}

//   @font-face {
// 	font-family: 'Averta';
// 	src: url('./assets/fonts/AvertaStd-Extrathin.ttf') format('ttf'),
// 		 url('path-to-font/AvertaStd-Light.ttf') format('ttf');
// 	font-weight: 300;
// 	font-style: normal;
//   }

//   @font-face {
// 	font-family: 'Averta';
// 	src: url('path-to-font/AvertaStd-Regular.ttf') format('ttf'),
// 		 url('path-to-font/AvertaStd-Regular.ttf') format('ttf');
// 	font-weight: 400;
// 	font-style: normal;
//   }

//   @font-face {
// 	font-family: 'Averta';
// 	src: url('path-to-font/AvertaStd-Semibold.ttf') format('ttf'),
// 		 url('path-to-font/AvertaStd-Semibold.ttf') format('ttf');
// 	font-weight: 600;
// 	font-style: normal;
//   }

//   @font-face {
// 	font-family: 'Averta';
// 	src: url('path-to-font/AvertaStd-Thin.ttf') format('ttf'),
// 		 url('path-to-font/AvertaStd-Thin.ttf') format('ttf');
// 	font-weight: 200;
// 	font-style: normal;
//   }

/* Example usage */
body {
	// font-family: 'Averta', sans-serif;
	font-family: 'Poppins', sans-serif;
}

*::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

*::-webkit-scrollbar-thumb {
	// background: var(--aquaBlue);
	border-radius: 10px;
}

*::-webkit-scrollbar-track {
	background: var(--darkSlate);
}

::-webkit-scrollbar-track {
	background-color: rgba(#1d2958, 0.2);
}

::-webkit-scrollbar {
	width: 2px;
	height: 2px;
}

::-webkit-scrollbar-thumb {
	// background-color: #b6b8f4;
}

::-webkit-scrollbar {
	width: 2px;
	height: 2px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
	height: 30px;
	background-color: transparent;
}

::-webkit-scrollbar-track-piece {
	background-color: #3b3b3b;
	-webkit-border-radius: 16px;
}

::-webkit-scrollbar-thumb:vertical {
	height: 50px;
	background-color: #666;
}

body {
	// font-family: 'Rubik', sans-serif;
	background: var(--darkGray);
}

.rounded-16 {
	border-radius: 16px !important;
}

.rounded-10 {
	border-radius: 10px !important;
}

.page-container {
	position: relative;
	transition: 300ms all ease-in-out;

	@media screen and (min-width: 1024px) {
		margin-left: var(--sidebarWidthExpand);

		&.sidebar-close {
			margin-left: var(--sidebarWidth);
		}
		&.chat-parent {
			margin-right: 24rem;
		}
	}
}

.sportbook {
	min-height: calc(-70px + 100vh);
	max-height: calc(-70px + 100vh);
	height: calc(-70px + 100vh);
	margin-top: 70px;
	@media screen and (max-width: 1200px) {
		min-height: calc(-130px + 100vh);
		max-height: calc(-130px + 100vh);
		height: calc(-130px + 100vh);
	}
	&.MuiGrid-root {
		height: 100%;
		min-height: 100%;
		max-height: 100%;
	}
}

.table.table-default {
	margin-bottom: 0;

	thead {
		tr {
			background: var(--aquaBlue);

			th {
				width: 1%;
				font-weight: 400;
				padding: 16px 10px;
				border: none;
				color: var(--white);

				&:first-child {
					border-radius: 14px 0 0 0;
				}

				&:last-child {
					border-radius: 0 14px 0 0;
				}

				font-size: 14px;
			}
		}
	}

	tbody {
		tr {
			td {
				color: var(--white);
				font-weight: 400;
				border: none;
				padding: 14px 10px;
				font-size: 13px;
				width: 5%;

				@media screen and (min-width: 767px) {
					font-size: 14px;
					font-weight: 600;
				}
			}

			.table-data-success {
				color: var(--green);
			}

			&:nth-child(even) {
				background: var(--darkSlate);
			}

			&:last-child {
				td {
					&:first-child {
						border-radius: 0 0 0 18px;
					}

					&:last-child {
						border-radius: 0 0 18px 0;
					}
				}
			}
		}
	}
}

.form-control {
	width: 100%;
	background-color: var(--inputbg);
	border: 1px solid var(--inputBorder);
	color: var(--white);
	padding: 7px 12px;
	border-radius: 8px;
	// height: 38px;
	height: 42px;
	font-weight: 500;
	font-size: 12px;

	&:focus {
		color: var(--white);
	}
}

.input-holder.position-relative .form-control {
	padding: 7px 35px 7px 12px;
}
input::placeholder {
	font-size: 13px !important;
	font-weight: 500;
}
.selectProvider__indicator-separator {
	background-color: var(--inputBorder) !important;
}

[type='text'],
[type='number'] {
	&.currency-btc-icon {
		background: url('../public/assets/images/common/currency-btc.png');
		background-repeat: no-repeat;
		background-size: 20px;
		background-position: calc(100% - 8px);
		padding-right: 34px;
	}

	&.percentage-icon {
		background: url('../public/assets/images/common/percentage.png');
		background-repeat: no-repeat;
		background-size: 14px;
		background-position: calc(100% - 8px);
		padding-right: 34px;
	}
}

.btn-bet,
.mainBtn {
	background: var(--green);
	color: var(--cinder);

	&:hover,
	&:active {
		background: var(--hoverGreen);
		color: var(--cinder);
	}
}

.auth-bg {
	background: url('../public/assets/images/homePage/auth-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 400px;
	min-width: 600px;
}

.auth-modal-wrap,
.search-modal-wrap {
	max-width: 71.75rem;

	@media (max-width: 767px) {
		min-width: 100%;
		padding: 0 10px;
		margin: 0;
	}

	.no-games {
		min-height: calc(70vh + 30px);
		display: flex !important;
		justify-content: center;
		align-items: center;
	}
}

.auth-img {
	height: 100%;
	overflow: hidden;
	border-radius: 15px;
	position: relative;

	.btn-close {
		position: absolute;
		opacity: 1;
		top: 0.625rem;
		right: 0.625rem;
		filter: invert(1);
		background-size: 0.825rem;
	}
}

.auth-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.auth-mob-instructions {
	padding: 1rem 0 0;

	p {
		font-size: 0.688rem;
		color: var(--white);
		font-weight: 600;
		line-height: 15px;
		margin: 0;
		text-align: center;

		a {
			color: var(--authTabActiveBorder);
			padding-left: 0.2rem;
		}
	}
}

.auth-instruction-text {
	position: absolute;
	width: 100%;
	bottom: 1rem;
	text-align: center;

	p {
		font-size: 0.875rem;
		color: var(--white);
		font-weight: 600;
		line-height: 20px;
		margin: 4px;

		a {
			color: var(--secondaryBtn);
			padding-left: 0.2rem;
		}
	}
}

.progress {
	height: 3px;
	background-color: #353b40;
}

@media (min-width: 1400px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1200px;
	}
}

.nav-tabs,
.nav-pills {
	column-gap: 5px;
	width: fit-content;
	padding: 6px;

	.nav-item {
		flex-grow: 1;

		.nav-link {
			border-radius: 8px;
			background: var(--authTabBtn);
			color: var(--authTabBtnText);
			font-weight: 600;
			border: 2px solid transparent;
			min-width: 96px;
			font-size: 0.875rem;
			margin-right: 0.313rem;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover,
			&.active {
				color: var(--authTabActiveBorder);
				border-color: var(--authTabActiveBorder);
			}
		}
	}

	&.nav-small {
		.nav-link {
			height: 35px;
		}
	}

	.nav-item {
		img {
			max-width: 30px;
			margin-right: 4px;
		}
	}
}

select {
	option {
		margin: 40px;
		background: var(--darkBlue);
		color: #fff;
	}
}

.text-green {
	color: var(--green);
}

.text-red {
	color: var(--red);
}

.modal-backdrop + .modal-backdrop {
	opacity: 0;
}

.modal .modal-content {
	background-color: var(--theme-dark);
	border-radius: 15px;
}

.form-group {
	position: relative;

	& .show-password {
		position: absolute;
		right: 10px;
		top: 7px;
		color: var(--authTabBtnText);
		cursor: pointer;

		&.label-with-password {
			top: 34px;
		}
	}
}

select {
	background: url('../public/assets/images/common/arrow-down-white.svg')
		no-repeat right #ddd;
	-webkit-appearance: none;
	background-position: calc(100% - 12px);
	background-size: 16px;
	padding-right: 35px !important;
}

button.btn {
	border-radius: 8px;
	transition: all 0.3s ease 0s;
}

.form-label {
	color: var(--white);
	font-size: 0.875rem;
	font-weight: 600;
	margin-bottom: 2px;
}

.input-group {
	.input-group-text {
		background-color: var(--btnBgGrey);
		border: 2px solid var(--btnBgGrey);

		.bet-amount-btn {
			&.active,
			&:hover {
				background-color: var(--primaryDark);
			}
		}
	}
}

.text-muted {
	color: var(--muted) !important;
}

.bet-amount-btn {
	background-color: var(--btnBgGrey);
	color: var(--white);
	padding: 9px 14px;

	&:hover {
		background-color: var(--btnBgGreyHover);
		color: var(--muted);
	}

	&.active {
		background-color: var(--primaryDark);
		color: var(--muted);
	}

	&.bet-amount-btn-wh-60 {
		width: 60px;
		height: 60px;
	}
}

input[disabled],
button[disabled],
.nav-item.disabled {
	cursor: not-allowed !important;
	pointer-events: all !important;
}
input[disabled] {
	background-color: #121c27 !important
	// background-color: #465361 !important
;
}

.btn.btn-default {
	background-color: var(--aquaBlue);
	color: var(--white);
	border-radius: 0.3rem;
	min-height: 44px;

	&:hover {
		background-color: var(--darkAqua);
	}
}

.cashout-column {
	min-width: 60px;
	display: inline-block;
	text-align: right;
}

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.border-muted {
	border-color: var(--borderMuted) !important;
}

.cursor-pointer {
	cursor: pointer;
}

.demo-play-casino {
	font-size: 10px;
	font-weight: bolder;
}

.overflowHidden {
	overflow: hidden;
}

.text-green {
	color: var(--hoverGreen);
}
.text-red {
	color: var(--red);
}

.bonus-modal {
	@media (max-width: '500px') {
		min-width: 250px;
	}
	.bonus-heading {
		margin-bottom: 1rem;
		font-weight: 600;
	}
	.bonusHeading {
		background: #1e2d3d;
		background: rgb(116, 116, 109);
		background: linear-gradient(
			94deg,
			rgba(116, 116, 109, 1) 0%,
			rgba(101, 98, 60, 1) 43%,
			rgba(134, 121, 16, 1) 100%
		);
		border-radius: 0.6rem;
		padding: 0.5rem;
		margin: 0.5rem 0 0.5rem 0;
		transition: 0.3s ease-in-out;
		overflow: hidden;
		justify-content: center;
		flex-direction: column;
		height: 8rem;
		display: flex;
		position: relative;
		img {
			position: absolute;
			width: 13rem;
			top: 0;
			right: 0;
		}

		.title-text {
			font-size: 1rem;
		}
	}
	.card-box {
		// background: #1e2d3d;
		// background: rgb(116, 116, 109);
		// background: linear-gradient(
		// 	94deg,
		// 	rgba(116, 116, 109, 1) 0%,
		// 	rgba(101, 98, 60, 1) 43%,
		// 	rgba(134, 121, 16, 1) 100%
		// );
		border-radius: 0.6rem;
		padding: 0.5rem;
		margin: 0.5rem 0 0.5rem 0;
		transition: 0.3s ease-in-out;
		background-color: var(--inputbg);
		border: 1px solid var(--inputBorder);

		// :hover {
		// 	cursor: pointer;
		// 	transition: 0.3s ease-in-out;
		// 	transform: scale(1.02);
		// 	// 	img {
		// 	// 	}
		// }
	}
	.card-container {
		padding: 0.5rem 1rem;
		// display: flex;
		// justify-content: space-around;
		// align-items: center;
		// gap: 0.5rem;
	}
	.card-heading {
		margin-bottom: 1rem;
		font-weight: 600;
		font-size: 14px;
		@media (max-width: '1199px') {
			text-align: end;
		}
	}
	.card-value {
		font-weight: 600;
		@media (max-width: '1199px') {
			text-align: end;
		}
	}
	.card-btn {
		align-items: center;
		display: flex;
		justify-content: center;
		font-weight: 600;
		button {
			font-weight: 600;
			width: 100%;
		}
	}
}

@media (max-width: 1023px) {
	#chat-widget-container {
		bottom: 50px !important;
		height: 95% !important;
	}
}
