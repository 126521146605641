.overlaywrap{
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--gradientHover);
  top: 0;
  display: none;
  text-align: center;
}
.overlaywrap img{
  width: 40px;
  margin:10px auto;
}
.overlaywrap p{
  text-align: center;
  color: #fff ;
  font-weight: 600;
}
.overlaywrap .play-btn{
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}


.crash-bg{
  background: url('../../../public/assets/images/homePage/crash-card.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  border-radius: 20px;
  background-position: 100%;
  display: flex;
  align-items: center;
}

.crash-banner-content{
  padding-left: 30px;
  color: #fff;
}
.crash-banner-content h2{
  font-weight: 800;
  font-size: 60px;
  line-height: 70px;
  margin: 0;
}
.crash-banner-content h5{
  font-size: 20px;
  line-height: 35px;
}
.crash-banner-content .btn{
  padding: 12px 40px;
  min-width: 186px;
  line-height: 24px;
}

.currencie-card h4 {
  color: #fff;
}
.view-all-games {
  margin: 20px 20px;
}

.game-play-wrapper .game-name {
  color: #fff;
  margin-left: 20px;
}
