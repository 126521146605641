// button.btn-close.btn-close-white {
//   position: absolute;
//   right: 30px;
//   top: 25px;
// }
// #login_modal.modal .modal-content {
//   background-color: var(--modalBG);
// }

// #login_modal .form-control {
//   background-color: var(--darkBlue);
//   border-radius: 60px;
//   border: 1px solid transparent;
//   padding: 15px 18px;
//   color: #fff
// }
// #login_modal .form-control:focus {
//   background-color: var(--darkBlue);
//   box-shadow: none !important;
//   color: var(--inputFocusText);
// }
input::placeholder {
	color: var(--authSocailTextColor) !important;
	font-size: 13px !important;
	font-weight: 500;
}
.auth-form-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.auth-form-wrap {
		padding: 15px;
		& .Sign-InText {
			color: #fff;
			font-weight: 600;
			font-size: 18px;
			margin: 0 0 1rem 0;
		}
		.auth-logo {
			text-align: center;
			svg {
				width: 80%;
				margin: 0 auto;
			}
		}
		.auth-socail-heading {
			margin-top: 1.25rem;
			margin-bottom: 1rem;
			h6 {
				color: var(--authSocailTextColor);
				text-align: center;
				position: relative;
				margin: 0;
				&::before {
					position: absolute;
					content: '';
					top: 0.625rem;
					left: 0;
					width: 100%;
					height: 1px;
					background: var(--authSocailColor);
				}
				span {
					background: var(--theme-dark);
					padding: 0 1rem;
					position: relative;
					font-size: 0.75rem;
				}
			}
		}

		.auth-btn-wrap {
			margin: 0.938rem 0;
			.btn {
				height: 50px;
			}
		}

		.auth-social-card {
			cursor: pointer;
			margin: 0 0.313rem;
			a {
				svg {
					width: 5rem;
				}
			}
			@media (max-width: 767px) {
				width: 80px;
				& img {
					width: 100%;
				}
			}
		}

		.auth-btn-wrap {
			& .create-Account-tag {
				display: flex;
			}
		}
		.Register-account {
			& .create-Account-tag {
				display: flex;
				gap: 0.5rem;
				& p {
					color: #fff;
					// font-weight: 400;
					font-family: 'Poppins', sans-serif;
					font-size: 14px;
				}
				& .register-button {
					font-weight: 600;
					font-size: 14px;
					cursor: pointer;
					color: var(--secondaryBtn);
				}
			}
		}

		// .nav-link {
		//   border-radius: 0.625rem;
		//   background: var(--authTabBtn);
		//   &.active{
		//     border:2px solid var(--authTabActiveBorder);
		//     background: var(--authTabActiveBtnBg);
		//   }
		// }
	}
	.social-logins {
		color: #b3bec1;
		width: 100%;
		text-align: center;
		padding-bottom: 1rem;
		& .social-elements {
			display: flex;
			flex-direction: column;
			gap: 0.8rem;
			& .login-text {
				display: flex;
				align-items: center;
				white-space: nowrap;
				font-size: 14px;
				gap: 5px;
				justify-content: center;
			}
			& .vertical-line {
				padding: 0.1px;
				background-color: #b3bec1;
				width: 100px;
			}
			& .social-icons {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 0.5rem;
			}
		}
	}
}
.custom-modal-size {
	max-width: 48%;
	width: 48%;

	@media (max-width: '1400px') {
		max-width: 60%;
		width: 60%;
	}

	@media (max-width: '600px') {
		max-width: none;
		width: auto;
	}
}

button.btn-close {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 999;
	filter: invert(1);
}
.forget-password {
	background: transparent;
	border: none;
	display: flex;
	// justify-content: end;
	// width: 100%;
	font-size: 13px;
	margin-left: auto;
	padding: 0.5rem 0rem;
}
.forget-password:hover {
	color: var(--secondry-border);
}
