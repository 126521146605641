.right-section-wrap.sidebar-close {
	padding-left: 66px;
}

span.menu-text {
	color: var(--authSocailTextColor);
}

// .sidebar-wrap {
// 	&.sidebar-close {
// 		.sidebar-balance-wrap,
// 		span.menu-text {
// 			display: none;
// 		}

// 		.sidebar-toggle-btn {
// 			justify-content: center;

// 			i {
// 				transform: rotate(180deg);
// 			}
// 		}

// 		.sidevar-menu-heading {
// 			visibility: hidden;
// 		}

// 		.sidebar-menu-list {
// 			padding: 0;

// 			p,
// 			.menu-arrow {
// 				display: none;
// 			}

// 			li {
// 				a {
// 					padding: 0;
// 					min-width: 100%;
// 					justify-content: center;
// 					line-height: 40px;
// 					border-radius: 0.5rem;

// 					@media (max-width: 1024px) {
// 						padding: 0 0.65rem;
// 					}

// 					&.active,
// 					&:hover {
// 						background: linear-gradient(
// 							90deg,
// 							rgba(255, 232, 26, 0.2),
// 							rgba(255, 232, 26, 0.01)
// 						);
// 					}

// 					.menu-icon {
// 						margin-right: 0;
// 					}

// 					.down-arrow {
// 						display: none !important;
// 					}
// 				}
// 			}
// 		}

// 		.sidebar-bottom-title {
// 			display: none;
// 		}
// 	}
// }

// .sidebar-balance-wrap {
// 	// background: var(--providerCard);
// 	padding: 0.625rem 0;
// 	// border:1px solid var( --theme-border);
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;

// 	h4 {
// 		color: var(--white);
// 		font-weight: 600;
// 		font-size: 1rem;

// 		span {
// 			color: var(--authSocailTextColor);
// 		}
// 	}

// 	p {
// 		font-size: 0.875rem;
// 		font-weight: 500;
// 		color: var(--authSocailTextColor);
// 		display: flex;
// 		align-items: center;
// 		margin: 0;

// 		span {
// 			color: var(--loss);
// 			padding-left: 0.513rem;
// 		}
// 	}

// 	.currency-icon {
// 		height: 48px;
// 		width: 48px;
// 		border-radius: 100%;
// 		border: 8px solid var(--theme-dark);
// 		overflow: hidden;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		margin-right: 0.625rem;
// 	}
// }

// .sidebar-wrap {
// 	font-weight: 800;
// 	width: 240px;
// 	position: fixed;
// 	top: 70px;
// 	// top: 0;
// 	height: 100dvh;
// 	transition: 0.4s all ease-in-out;
// 	padding: 1rem;
// }

// .sidebar-wrap.sidebar-close .sidebar-top-cta {
// 	display: none;
// }

// .sidebar-wrap.sidebar-close .sidebar-listing-wrap li a {
// 	padding: 10px 0;
// 	justify-content: center;
// }

// .sidebar-wrap.sidebar-close .sidebar-listing-wrap li {
// 	padding: 10px 0;
// }

// .sidebar-wrap.sidebar-close {
// 	width: 0;
// 	padding-left: 0;
// 	padding-right: 0;

// 	@media screen and (min-width: 1024px) {
// 		/* transform: translate(-180px); */
// 		width: 72px;
// 		padding-left: 1rem;
// 		padding-right: 1rem;
// 	}
// }

// .sidebar-wrap.sidebar-close .favourite-btn a {
// 	padding-left: 10px;
// }

// .sidebar-wrap {
// 	.favourite-btn {
// 		border: 0.900033px solid var(--gray);
// 		border-width: 1px 0;
// 		padding: 10px 5px;
// 		margin: 30px 20px 20px;
// 	}

// 	&.sidebar-close {
// 		.favourite-btn {
// 			margin: 30px 6px 20px;
// 		}
// 	}
// }

// .favourite-btn a span {
// 	padding-left: 16px;
// }

// .sidebar-wrap.sidebar-close .language-select-wrap select,
// .sidebar-wrap.sidebar-close .theme-switcher-wrap .toggle-slot {
// 	background: transparent;
// 	border: transparent;
// }

// .sidebar-wrap.sidebar-close .language-select-wrap img {
// 	left: -3px;
// }

// .sidebar-wrap.sidebar-close .sidebar-listing-wrap li a span,
// .sidebar-wrap.sidebar-close .favourite-btn a span,
// .sidebar-wrap.sidebar-close .language-wrap span,
// .sidebar-wrap.sidebar-close .theme-label,
// .sidebar-wrap.sidebar-close .theme-switcher-wrap .toggle-slot:after,
// .sidebar-wrap.sidebar-close .sidebar-ad-section {
// 	display: none;
// }

// .sidebar-wrap.sidebar-close .sidebar-listing-wrap li:hover a,
// .sidebar-wrap.sidebar-close .sidebar-listing-wrap li.active a {
// 	background: transparent;
// }

// .sidebar-wrap .sports-btn {
// 	height: 30px;
// 	width: 30px;
// 	border-radius: 100%;
// 	background: var(--cinder);
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .sports-listing {
// 	padding: 20px;
// }

// .sidebar-wrap .sports-btn img:nth-child(2),
// .sidebar-wrap .sports-btn:hover img:nth-child(1),
// .sidebar-wrap .sports-btn.active img:nth-child(1) {
// 	display: none;
// }

// .sidebar-wrap .sports-btn:hover img:nth-child(2),
// .sidebar-wrap .sports-btn.active img:nth-child(2) {
// 	display: block;
// }

// .sidebar-wrap .sports-btn:hover,
// .sidebar-wrap .sports-btn.active {
// 	background: var(--aquaBlue);
// }

// .sidebar-listing-wrap {
// 	padding: 20px 5px 0;
// }

// .sidebar-listing-wrap li {
// 	margin-bottom: 5px;
// }

// .sidebar-listing-wrap li a,
// .favourite-btn a {
// 	padding: 10px 20px;
// 	display: flex;
// 	align-items: center;
// 	position: relative;
// 	color: var(--white);
// 	border-radius: 30px;
// 	font-weight: 700;
// 	font-size: 16px;
// }

// .favourite-btn a {
// 	padding: 12px 5px;
// }

// .sidebar-listing-wrap li a span {
// 	padding-left: 35px;
// 	white-space: nowrap;
// }

// .sidebar-listing-wrap li a img {
// 	position: absolute;
// }

// .sidebar-listing-wrap .sidebar-submenu li img {
// 	width: 18px;
// 	height: 18px;
// }

// .sidebar-listing-wrap li:hover > a,
// .sidebar-listing-wrap li.active > a {
// 	background: var(--lightSlate);
// 	color: var(--white);
// }

// .sidebar-listing-wrap li:hover a svg {
// 	fill: var(--aquaBlue);
// }

// .sidebar-listing-wrap li a img:nth-child(2) {
// 	display: none;
// }

// .sidebar-listing-wrap li:hover > a img:nth-child(2),
// .sidebar-listing-wrap li.active > a img:nth-child(2) {
// 	display: block;
// 	filter: brightness(0) saturate(100%) invert(32%) sepia(96%) saturate(1267%)
// 		hue-rotate(173deg) brightness(95%) contrast(98%);
// }

// .sidebar-wrap .accordion-body {
// 	padding: 10px;
// }

// .sidebar-toggle-btn {
// 	background: transparent;
// 	// height: 1.375rem;
// 	width: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	border: none;
// 	transition: all 200ms ease-in-out;
// 	// border: 1px solid var(--secondry-border);
// 	// box-shadow: 0px 0px 4px var(--secondaryBtnBorder);
// 	padding: 0.5rem 0;

// 	img {
// 		transition: all 200ms ease-in-out;
// 	}

// 	i {
// 		font-size: 0.8rem;
// 	}
// }

// .sidebar-wrap.sidebar-close .sidebar-toggle-btn img {
// 	transform: rotate(180deg);
// }

// .sidebar-wrap.sidebar-close .sidebar-listing-wrap {
// 	padding-top: 50px;
// }

// .sidebar-wrap {
// 	z-index: 51;
// 	// box-shadow: 1px 0 1px #0b1216;
// 	background-color: var(--sidebar-bg);

// 	// .sidebar-toggle-btn {
// 	//   position: absolute;
// 	//   left: auto;
// 	//   top: 76px;
// 	//   right: -10px;

// 	//   @media(max-width: 767px) {
// 	//     right: 0;
// 	//     top: 6px;
// 	//   }
// 	// }

// 	&.sidebar-close {
// 		// .sidebar-toggle-btn {
// 		//   right: 0;
// 		//   left: -30px;

// 		//   @media screen and (min-width: 767px) {
// 		//     left: 10px;
// 		//     top: 20px;
// 		//     transform: rotate(180deg);
// 		//   }
// 		// }

// 		.sidebar-toggle-btn img {
// 			transform: rotate(180deg);
// 		}

// 		.sidebar-listing-wrap {
// 			padding-top: 50px;
// 		}
// 	}

// 	.sidebar-menu-list {
// 		li.inner-navigation-selected > a {
// 			color: var(--white) !important;
// 			text-shadow: inherit !important;

// 			svg {
// 				filter: inherit !important;

// 				path {
// 					fill: var(--white) !important;
// 				}
// 			}
// 		}

// 		padding: 0;

// 		h4 {
// 			color: var(--authSocailTextColor);
// 			font-size: 0.875rem;
// 			font-weight: 500;
// 			margin-bottom: 0.625rem;
// 			text-transform: uppercase;
// 		}

// 		li {
// 			margin-bottom: 0.313rem;
// 			background: #1a1d29;
// 			border-radius: 10px;

// 			& > a {
// 				display: block;
// 				padding: 0 0.65rem;
// 				color: var(--white);
// 				font-size: 0.875rem;
// 				font-weight: 500;
// 				display: flex;
// 				align-items: center;
// 				justify-content: space-between;
// 				border-radius: 8px;
// 				// transition: all 0.6s ease 0s;
// 				// border: 1px solid transparent;
// 				position: relative;
// 				min-width: 208px;
// 				line-height: 38px;
// 				margin: 7px 0;

// 				&:hover {
// 					background: linear-gradient(
// 						90deg,
// 						rgba(255, 232, 26, 0.2),
// 						rgba(255, 232, 26, 0.01)
// 					);
// 				}

// 				&.active {
// 					// background-color: var(--active-bg) !important;
// 					// background: rgb(255,232,26);
// 					background: linear-gradient(
// 						90deg,
// 						rgba(255, 232, 26, 0.2),
// 						rgba(255, 232, 26, 0.01)
// 					);
// 					color: var(--text-color);
// 					text-shadow: var(--text-shadow);

// 					&::after {
// 						content: '';
// 						position: absolute;
// 						inset: 0px;
// 						background: var(--activeBgColor);
// 						border-radius: 8px;
// 						z-index: -1;
// 					}

// 					.menu-arrow {
// 						display: none;
// 						background: var(--sidebarHover);

// 						i {
// 							color: var(--white);
// 						}
// 					}

// 					& svg {
// 						filter: var(--drop-shadow);

// 						path {
// 							fill: var(--text-color);
// 						}
// 					}

// 					i {
// 						color: var(--white);
// 						// transform: rotate(180deg);
// 					}
// 				}

// 				p {
// 					font-weight: 500;
// 					max-width: 120px;
// 					overflow-x: hidden;
// 					white-space: nowrap;
// 					text-overflow: ellipsis;
// 				}
// 			}

// 			.menu-arrow {
// 				padding: 0.1rem 0.625rem;
// 				border-radius: 0.375rem;
// 				text-align: center;
// 				background: var(--weekBg);
// 				color: var(--authSocailTextColor);
// 				position: absolute;
// 				right: 0.5rem;
// 				display: none;
// 			}
// 		}

// 		.menu-icon {
// 			margin-right: 0.625rem;

// 			svg {
// 				width: 1.1rem;
// 				height: 1.1rem;
// 				filter: brightness(10);
// 			}
// 		}

// 		.submenu-wrap {
// 			border-radius: 0;
// 			padding: 0;
// 			text-transform: capitalize;
// 			transition: all 500ms ease-in-out;
// 			animation: 400ms ease-in-out 0s 1 normal none running accordianEffect;

// 			li {
// 				&.active {
// 					a.active {
// 						background-color: var(--casino-filter-btn) !important;
// 					}
// 				}

// 				a {
// 					justify-content: flex-start;
// 					color: var(--white);

// 					& img {
// 						margin-right: 0.625rem;
// 						width: 1.125rem;
// 					}

// 					& svg {
// 						margin-right: 0.625rem;
// 						width: 1.1rem;
// 						height: 1.1rem;

// 						path {
// 							fill: var(--white);
// 						}
// 					}

// 					&.active {
// 						color: var(--text-color);
// 						text-shadow: var(--text-shadow) 0px 0px 8px;
// 						background: transparent;
// 						border-color: transparent;

// 						& svg {
// 							path {
// 								fill: var(--text-color);
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .sidebar-top-cta {
// 	background: var(--primaryDark);
// 	padding: 10px;
// 	justify-content: space-between;
// 	box-shadow: 0px 4px 4px var(--blackOpacity50);
// 	width: 100%;
// 	min-height: 48px;
// 	align-items: center;

// 	.nav-link {
// 		img {
// 			max-width: 25px;
// 			padding-left: 0 !important;
// 		}
// 	}
// }

// .nav-link.cta-btn {
// 	padding: 10px 12px;
// 	font-size: 14px;
// 	font-weight: 700;
// 	border-radius: 30px;
// 	color: var(--white);
// 	width: 100%;
// 	white-space: nowrap;
// }

// .sidebar-top-cta-wrap {
// 	padding: 10px 5px 0;
// }

// .advertisement-card {
// 	height: 180px;
// 	border-radius: 18px;
// 	overflow: hidden;
// }

// .advertisement-card img {
// 	width: 100%;
// 	height: 100%;
// 	object-fit: contain;
// }

// .theme-label span,
// .sidebar-ad-section p {
// 	font-size: 10px;
// 	color: var(--gray);
// 	line-height: 16px;
// 	font-weight: 400;
// 	display: block;
// }

// .sidebar-ad-section {
// 	padding: 20px;
// }

// .down-arrow {
// 	background: var(--hoverBg);
// 	height: 28px;
// 	width: 28px;
// 	border-radius: 5px;
// }

// .sidebar-close {
// 	.top-buttons {
// 		flex-direction: column;
// 		padding-bottom: 10px;

// 		button {
// 			width: 100%;
// 			height: 42px;
// 			border-radius: 0.4rem;

// 			span {
// 				display: none;
// 			}

// 			&:before {
// 				background-color: rgba(26, 29, 41, 0.6);
// 			}

// 			&.active {
// 				border: 1px solid var(--text-color);
// 				// filter: var(--drop-shadow);

// 				&:before {
// 					content: ' ';
// 					height: 100%;
// 					width: 100%;
// 					top: 0;
// 					left: 0;
// 					position: absolute;
// 					border-radius: 0.4rem;
// 				}
// 			}
// 		}
// 	}
// }

// .top-buttons {
// 	button {
// 		width: 100%;
// 		background-color: var(--casino-filter-btn);
// 		border: 1px solid var(--casino-filter-btn);
// 		padding: 0.5rem 0;
// 		border-radius: 0.4rem;
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		gap: 10px;
// 		font-size: 14px;
// 		font-weight: bold;
// 		background-repeat: no-repeat;
// 		background-size: cover;
// 		background-position: top left;
// 		position: relative;
// 		border: none;

// 		&.active {
// 			span {
// 				z-index: 9;
// 				color: var(--text-color);
// 				text-shadow: var(--text-shadow);
// 			}
// 		}

// 		span {
// 			z-index: 9;
// 		}

// 		&:before {
// 			content: ' ';
// 			height: 100%;
// 			width: 100%;
// 			background-color: rgba(26, 29, 41, 0.9);
// 			top: 0;
// 			left: 0;
// 			position: absolute;
// 			border-radius: 0.4rem;
// 		}
// 	}

// 	.sports-button {
// 		background-image: url('../../.../../../assets/images/sports.jpg');
// 		margin-top: 10px !important;
// 	}

// 	.casino-button {
// 		background-image: url('../../.../../../assets/images/casino.png');
// 	}
// }

// .sidebar-wrap {
// 	.hover-listing {
// 		display: none;
// 	}
// }

// .sidebar-top-toggle {
// 	display: none;
// }

// .sidebar-middle-toggle {
// 	display: flex;
// }

// .sidebar-wrap.sidebar-close {
// 	.sidebar-top-toggle {
// 		display: flex;
// 		padding-bottom: 20px;
// 	}

// 	.sidebar-middle-toggle {
// 		display: none;
// 	}

// 	.submenu-hover--parent {
// 		&:hover .hover-listing {
// 			display: block;
// 		}

// 		.hover-listing {
// 			display: none;
// 			position: fixed;
// 			z-index: 999999;
// 			overflow: visible;
// 			top: 70px;
// 			left: 43px;
// 			width: 10rem;
// 			background: var(--sidebar-bg);
// 			border-radius: 8px;

// 			ul {
// 				padding: 10px;

// 				li {
// 					a {
// 						justify-content: inherit;
// 						justify-content: inherit !important;
// 						gap: 10px;
// 						padding: 0 10px;
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// mobile bottom bar css start
.mobile-bottom-navbar {
	height: var(--mobileBottomNavbarHeight);
	background: var(--darkGunmetal-1000);
	border-top: 1px solid var(--black-500);
	display: none;
	z-index: 999;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;

	.list-inline {
		--bs-gutter-y: 0;
		display: flex;
		flex-wrap: wrap;
		margin-top: calc(var(--bs-gutter-y) * -1);

		.list-inline-item {
			flex: 1 0 0%;
			margin-right: 0 !important;
			padding: 10px;
			text-align: center;

			span {
				font-size: 12px;
				color: var(--white);
				white-space: nowrap;
			}

			&.active {
				img {
					filter: brightness(0) saturate(100%) invert(21%) sepia(74%)
						saturate(2846%) hue-rotate(177deg) brightness(94%) contrast(99%);
				}

				span {
					color: var(--aquaBlue);
				}
			}
		}
	}

	& button {
		padding: 0.25rem 1rem;

		& svg {
			filter: none;
			width: 1.2rem;

			path {
				fill: var(--button-text);
			}
		}
	}

	@media screen and (max-width: 1023.98px) {
		display: block;
	}
}

.sidebar-wrap {
	width: var(--sidebarWidthExpand);
	height: calc(100dvh - var(--headerHeight));
	background-color: var(--gunmetal-1000);
	box-shadow: 2px 0px 2px var(--darkGunmetal-1000);
	position: fixed;
	top: var(--headerHeight);
	left: 0;
	z-index: 51;
	padding: 1rem;
	transition: 300ms all ease-in-out;

	.sidebar-content-wrap {
		height: 100%;
		overflow: hidden auto;
		scrollbar-width: none;

		.nav-item-list {
			display: flex;
			flex-direction: column;
			gap: 0.25rem;

			.nav-item {
				.nav-link {
					min-width: 2.5rem;
					background-color: var(--darkGunmetal-1000);
					border: 0;
					border-radius: 0.5rem;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					gap: 0.75rem;
					z-index: 0;
					padding: 0.625rem 0.75rem;
					margin: 0;
					position: relative;
					transition: 200ms all ease-in-out;

					&::before {
						content: '';
						width: 100%;
						height: 100%;
						background: linear-gradient(
							90deg,
							rgba(255, 232, 26, 0.3),
							rgba(255, 232, 26, 0.1)
						);
						border-radius: inherit;
						opacity: 0;
						pointer-events: none;
						z-index: -1;
						position: absolute;
						inset: 0;
						transition: 200ms all ease-in-out;
					}

					.icon-box {
						width: 1.125rem;
						min-width: 1.125rem;
						height: 1.125rem;
						display: flex;
						justify-content: center;
						align-items: center;

						svg {
							width: 100%;
							height: 100%;
							fill: var(--white-1000);
						}
					}

					.text {
						color: var(--white-1000);
						font-size: 13px;
						font-weight: 600;
						line-height: 1.25;
						white-space: nowrap;
						text-overflow: ellipsis;
						display: block;
						overflow: hidden;
					}

					.arrow-icon-box {
						width: 1.125rem;
						min-width: 1.125rem;
						height: 1.125rem;
						display: flex;
						justify-content: center;
						align-items: center;

						svg {
							width: 100%;
							height: 100%;
							fill: var(--white-1000);
						}
					}

					&:hover,
					&.active {
						&::before {
							opacity: 1;
						}

						.icon-box {
							svg {
								fill: var(--veryPaleYellow-1000);
								filter: drop-shadow(var(--vividOrange-1000) 0px 0px 6px);
								-webkit-filter: drop-shadow(
									var(--vividOrange-1000) 0px 0px 6px
								);
							}
						}

						.text {
							color: var(--veryPaleYellow-1000);
							text-shadow: var(--vividOrange-1000) 0px 0px 8px;
						}
					}
				}

				.accordion {
					background-color: var(--darkGunmetal-1000);
					border-radius: 0.5rem;

					.accordion-item {
						.accordion-header {
							.accordion-button {
								min-width: 2.5rem;
								background-color: var(--darkGunmetal-1000);
								border: 0;
								border-radius: 0.5rem;
								display: flex;
								justify-content: flex-start;
								align-items: center;
								gap: 0.75rem;
								z-index: 0;
								padding: 0.625rem 0.75rem;
								margin: 0;
								position: relative;
								transition: 200ms all ease-in-out;
								&::before {
									content: '';
									width: 100%;
									height: 100%;
									// background: linear-gradient(
									// 	90deg,
									// 	rgba(255, 232, 26, 0.3),
									// 	rgba(255, 232, 26, 0.1)
									// );
									border-radius: inherit;
									opacity: 0;
									pointer-events: none;
									z-index: -1;
									position: absolute;
									inset: 0;
									transition: 200ms all ease-in-out;
								}

								.icon-box {
									width: 1.125rem;
									min-width: 1.125rem;
									height: 1.125rem;
									display: flex;
									justify-content: center;
									align-items: center;
									svg {
										filter: none !important;
										width: 100%;
										height: 100%;
										fill: var(--white-1000);
									}
								}

								.text {
									color: var(--white-1000) !important;
									font-size: 13px;
									font-weight: 600;
									line-height: 1.25;
									white-space: nowrap;
									text-overflow: ellipsis;
									display: block;
									overflow: hidden;
									text-shadow: none !important;
								}

								.arrow-icon-box {
									width: 1.125rem;
									min-width: 1.125rem;
									height: 1.125rem;
									display: flex;
									justify-content: center;
									align-items: center;

									svg {
										width: 100%;
										height: 100%;
										fill: var(--white-1000);
									}
								}

								&:hover,
								&[aria-expanded='true'] {
									&::before {
										opacity: 1;
									}

									.icon-box {
										svg {
											fill: var(--veryPaleYellow-1000);
											filter: drop-shadow(var(--vividOrange-1000) 0px 0px 6px);
											-webkit-filter: drop-shadow(
												var(--vividOrange-1000) 0px 0px 6px
											);
										}
									}

									.text {
										color: var(--veryPaleYellow-1000);
										text-shadow: var(--vividOrange-1000) 0px 0px 8px;
									}
								}
							}
						}

						.accordion-collapse {
							background-color: var(--darkGunmetal-1000);
							border-radius: 0.5rem;

							.accordion-body {
								padding: 0.25rem 0 0;

								.subnav-item-list {
									display: flex;
									flex-direction: column;
									gap: 0.25rem;

									.subnav-item {
										&.active {
											.icon-box {
												svg {
													fill: var(--veryPaleYellow-1000);
													filter: drop-shadow(
														var(--vividOrange-1000) 0px 0px 6px
													);
													-webkit-filter: drop-shadow(
														var(--vividOrange-1000) 0px 0px 6px
													);
												}
											}

											.text {
												color: var(--veryPaleYellow-1000);
												text-shadow: var(--vividOrange-1000) 0px 0px 8px;
											}
										}
										.subnav-link {
											min-width: 2.5rem;
											background-color: var(--darkGunmetal-1000);
											border: 0;
											border-radius: 0.5rem;
											display: flex;
											justify-content: flex-start;
											align-items: center;
											gap: 0.75rem;
											z-index: 0;
											padding: 0.625rem 0.75rem;
											margin: 0;
											position: relative;
											transition: 200ms all ease-in-out;
											.MuiSvgIcon-root {
												font-size: 1.05rem;
												color: #a0a0a0;
											}

											&::before {
												content: '';
												width: 100%;
												height: 100%;
												background: linear-gradient(
													90deg,
													rgba(255, 232, 26, 0.3),
													rgba(255, 232, 26, 0.1)
												);
												border-radius: inherit;
												opacity: 0;
												pointer-events: none;
												z-index: -1;
												position: absolute;
												inset: 0;
												transition: 200ms all ease-in-out;
											}

											.icon-box {
												width: 1.125rem;
												min-width: 1.125rem;
												height: 1.125rem;
												display: flex;
												justify-content: center;
												align-items: center;

												svg,
												img {
													width: 100%;
													height: 100%;
													fill: var(--white-1000);
												}
											}

											.text {
												color: var(--white-1000);
												font-size: 13px;
												font-weight: 600;
												line-height: 1.25;
												white-space: nowrap;
												text-overflow: ellipsis;
												display: block;
												overflow: hidden;
											}

											.arrow-icon-box {
												width: 1.125rem;
												min-width: 1.125rem;
												height: 1.125rem;
												display: flex;
												justify-content: center;
												align-items: center;

												svg {
													width: 100%;
													height: 100%;
													fill: var(--white-1000);
												}
											}

											&:hover,
											&.active {
												&::before {
													opacity: 1;
												}

												.icon-box {
													svg {
														fill: var(--veryPaleYellow-1000);
														filter: drop-shadow(
															var(--vividOrange-1000) 0px 0px 6px
														);
														-webkit-filter: drop-shadow(
															var(--vividOrange-1000) 0px 0px 6px
														);
													}
												}

												.text {
													color: var(--veryPaleYellow-1000);
													text-shadow: var(--vividOrange-1000) 0px 0px 8px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.sidebar-close {
		width: var(--sidebarWidth);
	}

	.extra-nav-items-list {
		width: 0;
		height: calc(100dvh - var(--headerHeight));
		background-color: var(--gunmetal-1000);
		display: block;
		padding: 1rem 0;
		position: absolute;
		top: 0;
		left: 100%;
		transition: 300ms all ease-in-out;

		&.extra-nav-items-list-show {
			width: var(--sidebarWidthExpand);
			border-left: 1px solid var(--black-500);
			border-right: 1px solid var(--black-500);
			padding: 1rem 1rem;
		}
	}

	@media screen and (max-width: 1023.98px) {
		height: calc(
			100dvh - var(--headerHeight) - var(--mobileBottomNavbarHeight)
		);

		&.sidebar-close {
			width: var(--sidebarWidthExpand);
			left: -100%;
		}
	}
}
