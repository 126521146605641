.chat-wrap {
	z-index: 1;
	background-color: var(--sidebar-bg);
	font-weight: 800;
	width: var(--chatDrawerWidth);
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	transition: width 0.3s ease;
	padding: 0rem;
	iframe {
		height: 100vh;
		width: 100%;
	}

	@media (max-width: 1199px) {
		iframe {
			height: 92vh;
			width: 100%;
		}
	}
	@media (max-width: 640px) {
		max-width: calc(100vw - 85px);

		iframe {
			margin-top: 4rem;
			height: calc(100vh - 7.8rem);
			width: 100%;
		}
	}
}

.chat-wrap.chat-close {
	width: 0;
	transition: width 0.3s ease;
}
