@media(max-width:1400px){
    .bonus-section{
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    .bonus-card{
        height: 110px;
        min-width:auto;
        margin-right: 10px;
        border-radius: 10px;
    }
    .home-filter-left{
        overflow-x: auto;
        margin-right: 20px;
        justify-content: flex-start;
    }
    .home-filter-left a{
        white-space: nowrap;
    }
    .home-filter-right input{
        min-width: 280px;
    }
    .currencie-card ul{
        overflow-x: auto;
    }
    // .brand-logo {
    //     width: 200px;
    // }

    header .btn span{
        // display: none;
    }
    .footer-linking{
        padding: 0 10px;
    }
    .footer-linking a{
        font-size: 10px;
    }
    .email-verification-container{
        min-height: 52vh
    }

}
@media(max-width:991px){
    header .btn span, header .btn-secondary.web-btn, .web-btn, .sidebar-close .mob-user-balance-wrap{
        // display: none;
    }


    .mob-user-balance-wrap{
        margin-top: 15px;
        border: 1px solid  var(--darkAqua);
        border-width: 1px 0;
    }
    .mob-user-balance-wrap button{
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    // .brand-logo {
    //     width: 120px;
    // }

    header .btn{
        font-size: 10px;
        padding: 2px 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 30px;
    }
    // header.sidebar-close{
    //     padding-left: 60px;
    // }

    .bonus-card{
        height: 80px;
        min-width: auto;
    }

    .home-filter-left a{
        font-size: 12px;
        padding: 10px;
    }

    .home-filter-right input{
        min-width: 100%;
        margin-top: 10px;
    }
    .form-control::placeholder{
        font-size: 12px;
    }
    .home-filter-right .search-icon{
        width: 22px;
    }
    .home-filter-wrap{
        margin-bottom: 80px;
    }
    .theme-card-heading p{
        font-size: 12px;
    }
    .crash-bg{
        min-height: 150px;
        background-position: 80% 50%;
    }
    .crash-banner-content .btn{
        min-width: auto;
        font-size: 10px;
        padding: 4px 20px;
        line-height: 18px;
    }
    .crash-banner-content h2{
        font-size: 30px;
        line-height: 40px;
    }
    .crash-banner-content h5 {
        font-size: 10px;
        line-height: 20px;
    }
    .crash-banner-content{
        padding-left: 15px;
    }
    .overlaywrap img{
        width: 20px;
    }
    .overlaywrap p{
        font-size: 12px;
    }
    .right-section-wrap{
        padding: 15px 15px 0 70px;
    }

    .currencie-card li{
        flex-flow: column;
    }
    .currencie-card li img{
        margin: 0 0 10px;
        width: 40px;
    }
    .currencie-card li h4{
        font-size: 10px;
    }
    .table{
        font-size: 10px;
        white-space: nowrap;
    }
    .table td{
        font-size: 10px;
    }
    // .footer-top{
    //     padding: 20px 10px;
    // }
    .footer-linking{
        border: none;
    }
    .footer-about p{
        font-size: 9px;
        line-height: 14px;
    }
    .footer-linking{
        padding: 0;
    }
    .footer-linking a{
        font-size: 9px;
    }
    .footer-contact{
        padding-left: 0;
        margin-top: 20px;
    }
    .social-listing a img{
        width: 20px;
    }

    .betslip-header{
        width: 100px;
        border-radius: 9px;
        margin-left: auto;
        padding: 5px 10px;

    }

    .betslip-header .bet-copy-icon {
        width: 15px;
    }
    .betslip-wrap.betslip-active .betslip-header{
        width: 100%;
        border-radius: 18px 18px 0px 0px;
        padding: 18px 20px;
    }
    .betslip-toggle-btn img{
        width: 10px;
    }
    .betslip-wrap{
        right: 10px;
        bottom: -10px;
        background: transparent;
    }
    .betslip-wrap.betslip-active{
        background: var(--darkSlate);
        right: 0;
        bottom: 0;
    }
    .betslip-header h4{
        font-size: 10px;
    }
    .betslip-wrap.betslip-active .betslip-toggle-btn img{
        width: 14px;
    }
    .betslip-wrap.betslip-active  .bet-copy-icon{
        width: 20px;
    }
    .betslip-wrap.betslip-active  .betslip-header h4{
        font-size: 14px;
    }
    .sidebar-content-wrap{
        z-index: 2;
    }
    .copyright-section p{
        font-size: 10px;
    }
    .sidebar-listing-wrap li a span, .favourite-btn a span, .sidebar-listing-wrap .nav-pills .nav-link{
        font-size: 12px;
    }
    .slider-heading {
        margin-bottom: 5px;
    }
    .sports-table-section .home-filter-wrap{
        margin-bottom: 0;
    }
    .sidebar-top-cta-wrap .accordion-button span{
        font-size: 12px;
    }
    .live-match-participants h3, .live-match-participants p{
        font-size: 10px;
        margin: 2px 0;
    }
    .match-score-heading, .live-match-name span{
        font-size: 10px;
    }
    .live-match-banner .live-match-left-section{
        padding: 40px 10px;
    }
    .match-type-listing .nav{
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    .right-section-content{
      min-height: 680px;
    }
    header{
        padding-left: 0;
    }
    .accordion .accordion-item .accordion-header .accordion-button .text, .accordion .accordion-item .accordion-header .accordion-button .figure-text{
        font-size: 20px;
    }
    .accordion .accordion-item .accordion-header .accordion-button div{
        align-items: center;
    }
    .head-sub-text{
        font-size: 14px;
    }
    .withdrawal-section{
        padding-left: 0;
        padding-right: 0;
    }
    .promotion-tabs-section .nav-pills .nav-link{
        min-width: 200px;
        padding: 15px 10px;
    }
    .promotion-tabs-section .nav{
        overflow-x: auto;
        overflow-y: hidden;
    }
    .promotion-tabs-section .nav li button:after{
        background-size: 20px;
        height: 20px;
        width: 20px;
        bottom: -3px;
    }
    .promotion-tabs-section .theme-card{
        padding: 50px 20px;
    }
    .email-verification-container{
        min-height: 51vh
    }
}
@media(max-width:767px){
    .bonus-card {
        height: 110px;
        min-width: 80%;
    }
    .crash-bet-right, .crash-bet-left{
        width: 100%;
    }
    .bet-btn{
        min-height: auto;
        font-size: 16px;
        border-radius: 10px;

    }
    .betslip-wrap{
        min-width: 100%;
        background: transparent;
    }
    .slider-heading {
        margin-bottom: 55px;
    }
    .home-filter-left{
        justify-content: flex-start;
    }
    .currencie-card li{
        min-width: 120px;
    }
    .my-profile-section .profile-img-container .img-box{
        height: 100px;
        width: 100px;
    }
    .head-title-text{
        font-size: 18px;
    }
    .crash-bet-right{
        padding: 0 ;
    }
    .email-verification-container{
        min-height: 41vh
    }

}
@media screen and (max-width: 768px) {
    .modal-content .modal-body {
        // padding: 18px 34px;
        width: 100%;
    }
    .modal .modal-content {
        width: 100%;
    }
    .filter-section {
        flex-direction: column;
        .filter-data {
        flex-direction: column;
        }
    }
}
@media screen and (min-width: 768px) {
    .modal-content .modal-body {
        padding: 18px 34px;
        width: 100%;
    }
    .modal.login-signup-modal .modal-content .modal-body {
        width: 600px;
        padding: 18px 34px;
    }
    // .theme-card{
    //     padding: 10px;
    // }
}

// @media (min-width: 992px){
//   .modal-lg, .modal-xl {
//         max-width: 500px;
//  }
//   .signup .modal-dialog {
//         max-width: 800px;
//  }
// }

// @media screen and (max-width: 358px) {
//     header .btn{
//         font-size: 8px;
//         padding: 2px 10px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         line-height: 30px;
//     }
// }
